/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Hero Area-L7
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.hero-area-l7 {
   position: relative;
   z-index: 1;
   padding-top: 60px;
   padding-bottom: 20px;
   margin: 70px 0px 0px 0px;

   @include mobile {
      margin: 80px 0px 0px 0px;
   }

   @include tablet {
      padding-top: 80px;
      padding-bottom: 32px;
   }
   @include desktops {
      margin: 90px 0px 0px 0px;
      padding-top: 150px;
      padding-bottom: 150px;
   }
   @include large-desktops-mid {
      border-radius: 15px;
      margin: 90px 20px 0px 20px;
     
   }

   .hero-l7-content {
      padding-top: 45px;

      @include desktops {
         padding-top: 0;


      }

      @include large-desktops {
         padding-right: 35px;
      }


      h2 {
         font-weight: 600;
         font-size: 36px;
         line-height: 46px;
         letter-spacing: -1px;
         color: $white;
         margin-bottom: 30px;

         @include mobile-lg {
            font-size: 40px;
            line-height: 46px;
         }

         @include tablet {
            font-size: 48px;
            line-height: 54px;
         }

         @include desktops {
            font-size: 60px;
            line-height: 66px;
         }


      }

      p {
         font-size: 17px;
         line-height: 30px;
         color: $white;
         opacity: 0.8;
         margin-bottom: 30px;

         @include tablet {
            margin-bottom: 40px;
         }

         @include desktops {
            margin-bottom: 60px;
         }
      }

   }

}

.hero-slider-single-item {

   .image {
      border-radius: 15px 15px 0px 0px !important;
      & img {
         border-radius: 15px 15px 0px 0px;
      }
   }

   .content-box {
      background: $white;
      border-radius: 0px 0px 15px 15px;
      padding: 25px;

      .item {
         margin: 0px 8px;
      }

      p {
         font-size: 13px;
         line-height: 22px;
         color: $mirage;
         opacity: 0.7;
         margin-bottom: 3px;
      }

      span {
         font-weight: 600;
         font-size: 15px;
         line-height: 26px;
         color: $java;
      }
   }
}

.view-property-btn {
   margin-top: 20px !important;

   @include mobile {
      margin-top: 0 !important;
   }

   .btn {
      background: $primary;
      border-radius: 5px;
      min-width: 132px;
      height: 40px;
      font-weight: 600;
      font-size: 15px;
      line-height: 26px;
      color: $white;
   }

}

.hero-l7-slider {
   .slick-dots {
      margin-top: 60px;
      justify-content: left;
   }

   .slick-dots li {
      background-color: $white;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 5px;
      transition: 0.4s;
      cursor: pointer;
   }

   .slick-dots li button {
      background-color: transparent;
      border: none;
      color: transparent;
   }

   .slick-dots {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      margin-top: 25px;
      justify-content: center;
      border-radius: 4px;
   }

   .slick-dots li.slick-active {
      background-color: $java;
   }

   .slick-dots li button:focus {
      outline: none;
      box-shadow: none;
   }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Feature Area-L7
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-l7 {
   padding-top: 55px;

   @include tablet {
      padding-top: 75px;
   }

   @include desktops {
      padding-top: 100px;
   }
}

.feature-area-l7-items {
   margin-bottom: -30px;

   .col-lg-4 {
      margin-bottom: 30px;
   }
}

.feature-card-l7 {
  
   @include tablet {
      padding-right: 45px;
   }
   @include desktops {
      padding-right: 5px;
   }
 
   @include large-desktops {
      padding-right: 65px;
   }
   @include extra-large-desktops {
      padding-right: 125px;
   }
   .icon {
      margin-bottom: 45px;
   }

   .content {
      h4 {
         font-weight: 600;
         font-size: 24px;
         line-height: 32px;
         letter-spacing: -0.3px;
         color: $mirage;
         margin-bottom: 15px;
      }

      p {
         font-size: 15px;
         line-height: 28px;
         color: $mirage;
         opacity: 0.8;
      }
   }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Content Area 1-L7
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.content-area-l7-1 {
   background: #D6EBE4;

   position: relative;
   z-index: 1;
   padding-top: 60px;
   padding-bottom: 60px;
   margin: 40px 0px 0px 0px;

   @include tablet {
      margin: 57px 0px 0px 0px;
      padding-top: 80px;
      padding-bottom: 80px;
   }

   @include large-desktops-mid {
      border-radius: 15px;
      margin: 80px 20px 0px 20px;
      padding-top: 130px;
      padding-bottom: 130px;
   }

   .content-l7-1-content-box {
      padding-top: 45px;

      @include desktops {
         padding-top: 0;


      }

      @include extra-large-desktops {
         padding-right: 75px;
      }

   }

}

.content-1-l7-service {
   padding-top: 30px;

   @include tablet {
      padding-top: 43px;
   }

   @include desktops {
      padding-top: 63px;
   }

   .content-box {
      margin-bottom: 30px;

      .item {
         width: 20%;
      }

      p {
         font-weight: 600;
         font-size: 15px;
         line-height: 26px;
         color: $mirage;
         margin-bottom: 3px;
      }

      span {
         font-size: 15px;
         line-height: 28px;
         color: $mirage;
         opacity: 0.7;
      }
   }
}

.content-1-l7-image {
   & img {
      border: 8px solid $white;
      border-radius: 15px;
   }
}

.content-1-l7-btn-group {
   margin-top: 55px;
   @include mobile {
      margin: 55px -5px 0px -5px;
      a {
         margin: 0px 5px;
      }
   }
}

.l7-view-pro-btn {
   background: $white;
   min-width: 100%;
   height: 55px;
   font-weight: 600;
   font-size: 17px;
   line-height: 30px;
   border-radius: 3px;
   color: $mirage;

   @include mobile {
      min-width: 200px;
   }
}

.l7-book-pro-btn {
   background: $java;
   min-width: 100%;
   height: 55px;
   font-weight: 600;
   font-size: 17px;
   line-height: 30px;
   border-radius: 3px;
   color: $white;
   margin-top: 20px !important;

   @include mobile {
      min-width: 200px;
      margin-top: 0 !important;
   }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Content Area 2-L7
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.content-area-l7-2 {
   padding-top: 50px;
   padding-bottom: 55px;

   @include tablet {
      padding-top: 70px;
      padding-bottom: 55px;
   }

   @include desktops {
      padding-top: 120px;
      padding-bottom: 125px;
   }

   .section__heading-3 {
      h2 {

         @include large-desktops {
            padding-right: 86px;
         }

         @include extra-large-desktops {
            padding-right: 86px;
         }
      }
   }
}

.content-area-l7-2-heading {
   padding-bottom: 45px;

   @include tablet {
      padding-bottom: 45px;
   }

   @include desktops {
      padding-bottom: 45px;
   }
}

.content-l7-2-content-box {
   margin-top: 45px;
   margin-bottom: -20px;
   @include tablet {
      margin-bottom: -25px;
   }
   @include desktops {
      margin-top: 0;
   }

   .item {
      margin-bottom: 20px;
      @include tablet {
         margin-bottom: 25px;
      }
    
      .item-icon {

         width: 60px;
         height: 60px;
         line-height: 60px;
         border-radius: 50%;
         text-align: center;
         color: $white;
         border: 1px solid #E1ECF0;
         margin-bottom: 30px;
        
         @include mobile {
            margin-right: 30px;
         }

      }

      .item-icon span {
         color: $mirage;
         font-size: 22px;
         font-weight: 700;
         font-style: normal;
         letter-spacing: -0.73px;
         line-height: normal;
      }

      .item-content {
         h4 {
            font-weight: 600;
            font-size: 21px;
            line-height: 30px;
            color: $mirage;
         }

         p {
            font-size: 15px;
            line-height: 28px;
            color: $mirage;
            opacity: 0.7;
            margin-bottom: 0;
         }
      }
   }


}

.content-2-l7-image {
   & img {
      border-radius: 15px;
   }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
 Testimonial-L7
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.testimonial-area-l7 {
   padding-bottom: 60px;

   @include tablet {
      padding-bottom: 80px;
   }

   @include desktops {
      padding-bottom: 130px;
   }
}

.testimonial-area-l7-left-side {
   .video-card {
      position: absolute;
      bottom: 20px;
      left: 20px;
      background: #FFFFFF;
      border-radius: 15px;
      padding: 20px 25px 20px 25px;
      width: calc(100% - 40px);

      .content {
         h6 {
            font-weight: 600;
            font-size: 21px;
            line-height: 30px;
            color: $mirage;
            margin-bottom: 0;
         }

         span {
            font-size: 13px;
            line-height: 22px;
            color: $mirage;
            opacity: 0.6;
         }
      }
   }
}

.testimonial-area-l7-right-side {
   .section__heading-3 {
      margin-top: 30px;

   }
}

.video-icon img {
      border: 2px solid #FF794D;
      border-radius: 50%;
      padding: 12px;
      margin-right: 12px;
}

.testimonial-content-l7 {
   margin-top: 25px;
   background: #FF794D;
   border-radius: 15px;
   padding: 30px;
   @include mobile {
      padding: 50px;
   }
   .user {
      & img {
         border-radius: 50%;
      }
   }

   .content {
      margin-top: 40px;

      h4 {
         font-weight: 600;
         font-size: 21px;
         line-height: 30px;
         color: $white;
         margin-bottom: 10px;
      }

      p {
         font-size: 15px;
         line-height: 28px;
         color: $white;
         opacity: 0.9;
         margin-bottom: 0;
      }
   }
}

.testimonial-right-content-image-l7 {
   margin-top: 45px;

   @include desktops {
      margin-top: 30px;
      position: absolute;
      bottom: 0;
   }

}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Footer Area-L3
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/





.footer-area-l7 {
   background: rgba(250, 229, 218,0.25);
   padding-top: 53px;
   padding-bottom: 50px;

   @include tablet {
      padding-top: 70px;
      padding-bottom: 70px;
   }

   @include desktops {
      padding-top: 100px;
      padding-bottom: 100px;
   }

   @include large-desktops-mid {
      border-radius: 15px;
      margin: 0px 20px 20px 20px;
   }
}

.cta-area-l7 {
   .cta-l7-content {
      h2 {
         font-weight: 600;
         font-size: 34px;
         line-height: 44px;
         letter-spacing: -1px;
         color: #1D263A;

         @include tablet {
            font-size: 38px;
            line-height: 48px;
         }

         @include desktops {
            font-size: 48px;
            line-height: 58px;
         }

      }

      p {
         font-size: 15px;
         line-height: 28px;
         color: #1D263A;
         opacity: 0.7
      }
   }
}

.cta-border-l7 {
   border-bottom: 1px solid rgba(181, 181, 181, 0.13);
   margin: 60px 0px 60px 0px;

   @include tablet {
      margin: 50px 0px 80px 0px;
   }

   @include desktops {
      margin: 70px 0px 130px 0px;
   }
}

.cta-l7-btn {
   margin-top: 30px;

   @include tablet {
      margin-top: 0;
   }

   .btn {
      background: $java;
      border-radius: 3px;
      min-width: 263px;
      height: 55px;
      font-weight: 600;
      font-size: 17px;
      line-height: 30px;
      color: #FFFFFF;
   }
}

.footer-l7-area-items {
   margin-bottom: -30px;

   .col-xl-4 {
      margin-bottom: 30px;
   }

   .col-xl-2 {
      margin-bottom: 30px;
   }

   .footer-content-l7 {
      & img {
         margin-bottom: 50px;
      }

      p {
         font-size: 17px;
         line-height: 30px;
         color: $mirage;
         opacity: 0.8;
      }
   }

   h3 {
      font-weight: 600;
      font-size: 15px;
      line-height: 26px;
      color: $mirage;
      opacity: 0.6;
      margin-bottom: 30px;

      @include desktops {
         margin-bottom: 43px;
      }
   }

   ul {
      margin-bottom: -20px;

      li {
         margin-bottom: 20px;

         a {
            font-weight: 600;
            font-size: 17px;
            line-height: 30px;
            color: $mirage;

         }
      }
   }
}