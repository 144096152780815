/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Hero Area-L-11
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.landing-11-menu {
   .site-menu-main .nav-link-item {
      font-family: Karla;
      color: #1f1f1f;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.4px;

      i {
         color: #1f1f1f;
      }
   }
}

.landing-11-menu {
   .site-menu-main .sub-menu--item {
      font-family: Karla;
      color: #1f1f1f;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.4px;
   }
}

.landing-11-menu {
   &.reveal-header .log-in-btn {
      color: #666666 !important;
   }
}


.hero-area-l11 {
   padding-top: 100px;
   padding-bottom: 60px;

   @include mobile {
      padding-top: 115px;
   }

   @include tablet {
      padding-top: 130px;
      padding-bottom: 76px;
   }

   @include desktops {
      padding-top: 180px;
      padding-bottom: 50px;
   }

   .content {
      padding-top: 20px;
      position: relative;
      z-index: 5;

      @include desktops {
         padding-top: 0;

      }

      h1 {
         font-family: 'Gothic A1', sans-serif;
         color: #1F1F1F;
         font-size: 34px;
         font-weight: 800;
         font-style: normal;
         letter-spacing: -3px;
         line-height: 40px;
         margin-bottom: 38px;

         @include mobile-lg {
            font-size: 40px;
            line-height: 46px;
         }

         @include tablet {
            font-size: 48px;
            line-height: 54px;
         }

         @include large-desktops {
            font-size: 70px;
            line-height: 86px;
         }
      }

      p {
         font-family: Karla;
         color: #222F3F;
         font-size: 21px;
         font-weight: 400;
         font-style: normal;
         letter-spacing: -0.4px;
         line-height: 36px;



      }


   }

   .banner-main-content-l-11 {
      @include large-desktops {
         padding-right:65px;
      }
      @include extra-large-desktops {
         padding-right: 130px;
      }
     
   }

   .banner-sign-text {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 36px;
      letter-spacing: -0.32381px;
      color: #878B90;
      margin-top: 7px;

      a {
         color: #258AFF;
      }
   }
   .compatible-icon {
      margin: 0px -11px 0px -11px;
   
      a {
         margin: 0px 11px 0px 11px;
      }
   }
   
   .compitable-text {
      margin-top: 25px;
      @include tablet {
         margin-top: 8px;
      }
      p {
         padding-top: 20px;
      }
   }
}

.header-btn-l11 {
   .log-in-btn {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -1px;
      color: #666666;
      min-width: 87px;
      height: 40px;
      border: 1px solid #1F1F1F;
      border-radius: 3px;
      margin-right: 15px;
   }

   .download-trail-btn {
      font-family: Karla;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-align: center;
      letter-spacing: -1px;
      color: #FFFFFF;
      background: #258AFF;
      border-radius: 3px;
      min-width: 137px;
      height: 40px;
   }
}


.l-11-hero-btn {
   min-width: 205px;
   height: 60px;
   background: #258AFF;
   border-radius: 3px;
   font-family: Karla;
   font-style: normal;
   font-weight: bold;
   font-size: 18px;
   line-height: 21px;
   text-align: center;
   letter-spacing: -0.9px;
   color: #FFFFFF;
}

.hero-shape-l11-1 {
   position: absolute;
   left: 0;
   top: 30%;
   @include tablet {
      left:-5%;
   }
   @include desktops {
      left: 0;
      top: 30%;
   }
}

.hero-shape-l11-2 {
   position: absolute;
   right: 0;
   top: 40%;
}

.hero-l11-main-image {
 
   @include desktops {
      margin-top: 90px;
   }
}



.img-grayscale {
   filter: grayscale(100%);
}

.banner-l-11-bottom-content {
   padding-left: 30px;
}

.line-left-content {
   position: absolute;
   top: 0;
   left: -30px;
   width: 7px;
   height: 55px;
   background: #E8F1FC;
   border-radius: 4.5px;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
Brand Area L-11
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
        .brand-area-l11{
           .brand-logos{
              margin: 0px -20px -45px -20px;
              .single-brand{
                 margin: 0px 20px 45px 20px;
              }
           }
        }

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
Content Area-1 L-11-1
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-11-1 {
   padding-top: 60px;

   @include tablet {
      padding-top: 80px;
   }

   @include desktops {
      padding-top: 130px;
   }

   .content {
      padding-top: 45px;
      @include desktops {
         padding-top: 0;  
      }
      @include large-desktops {
         padding-right: 55px;
      }
      @include extra-large-desktops {
         padding-right: 50px;
      }
     
   }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
Content Area-1 L-11-2
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-11-2 {
   padding-top: 50px;
   padding-bottom: 42px;

   @include tablet {
      padding-top: 70px;
      padding-bottom: 62px;
   }

   @include desktops {
      padding-top: 125px;
      padding-bottom: 110px;
   }

   .section-heading-5 {
      h2 {
         margin-bottom: 40px;
         @include desktops {
            margin-bottom: 60px;
         }
      }
   }

   .content {
      padding-top: 45px;
      @include desktops {
         padding-top: 0;  
      }
      h5 {
         font-family: "Gothic A1";
         font-size: 18px;
         font-weight: 700;
         line-height: 30px;
         color: #000000;
      }

      p {
         font-family: Karla;
         font-style: normal;
         font-weight: normal;
         font-size: 16px;
         line-height: 30px;
         letter-spacing: -0.4px;
         color: #333333;
      }

      .content-l-11-3-card {
         border: 1px solid #e5e5e5 !important;
         border-radius: 5px;
         padding: 20px 35px 20px 35px;
         margin-bottom: 15px;
         align-items: end;

         svg {
            margin-right: 25px;
         }

         img {
            margin-right: 25px;
         }
      }
   }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Content Area-L-11 3
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.content-area-l-11-3 {
   padding-bottom: 60px;

   @include tablet {
      padding-bottom: 80px;
   }

   @include desktops {
      padding-bottom: 130px;
   }


   .content {
      padding-top: 45px;

      @include desktops {
         padding-top: 0;
      }

   }

   ul {
      margin-top: 50px;
      margin-bottom: -20px;

      li {
         font-family: Karla;
         font-style: normal;
         font-weight: normal;
         font-size: 24px;
         line-height: 32px;
         letter-spacing: -1.4px;
         color: #000000;
         margin-bottom: 20px;

         i {
            color: #258AFF;
            margin-right: 20px;
            font-size: 18px;
         }
      }
   }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Feature Area-L-11 1
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-l-11 {
   background: #192F48;
   padding-top: 55px;
   padding-bottom: 40px;

   @include tablet {
      padding-top: 75px;
      padding-bottom: 60px;
   }

   @include desktops {
      padding-top: 125px;
      padding-bottom: 110px;
   }

   .section-heading-5 {
      padding-bottom: 40px;
      @include tablet {
         padding-bottom: 60px;
      }
    
      @include desktops {
         padding-bottom: 80px;
      }
      h2 {
         color: #fff;
      }

      p {
         color: rgba(255, 255, 255, 0.9);
      }
   }
}

.feature-l-11-items {
   margin-bottom: -37px;

   .col-md-6 {
      margin-bottom: 37px;
   }

   .icon-box {
      margin-right: 25px;

      i {
         width: 60px;
         height: 60px;
         line-height: 60px;
         text-align: center;
         background: #258AFF;
         border-radius: 5px;
         color: #fff;
         font-size: 30px;
      }
   }

   .content-body {
   
      @include extra-large-desktops {
         padding-right: 60px;
      }
      h5 {
         font-family: Gothic A1;
         font-style: normal;
         font-weight: 800;
         font-size: 21px;
         line-height: 26px;
         letter-spacing: -1px;
         color: #FFFFFF;
      }

      p {
         font-family: Karla;
         font-style: normal;
         font-weight: normal;
         font-size: 17px;
         line-height: 28px;
         letter-spacing: -0.4px;
         color: #BBC6D2;
      }
   }
}




/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Testimonial Area-L-11 1
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.testimonial-area-l-11 {
   padding-top: 55px;
   padding-bottom: 70px;

   @include tablet {
      padding-top: 75px;
      padding-bottom: 95px;
   }

   @include desktops {
      padding-top: 125px;
      padding-bottom: 170px;
   }

   .section-heading-5 {
      padding-bottom: 40px;
      @include desktops {
         padding-bottom: 60px;
      }
   }
}

.testimonial-slider-l-11 {
   padding: 0px 50px;

   .l-11-slide-btn {
      cursor: pointer;

      &.slick-prev {
         position: absolute;
         top: 50%;
         left: 0%;
      }

      &.slick-next {
         position: absolute;
         top: 50%;
         right: 0;
      }
   }
   .testimonial-card {
      @include desktops {
         padding: 0px 41px;
      }
    
      @include extra-large-desktops {
         padding: 0px 50px;
      }
     
   
      p {
         font-family: Karla;
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 32px;
         letter-spacing: -0.4px;
         color: #333333;
   
      }
   
      .user-details {
         margin-top: 30px;
         @include desktops {
            margin-top: 60px;
         }
       
   
         img {
            margin-right: 22px;
         }
   
         h5 {
            font-family: Gothic A1;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 0;
            color: #000000;
         }
   
         span {
            font-family: Karla;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 28px;
            letter-spacing: -0.642857px;
            color: #666666;
         }
      }
   }
}







/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Newsletter-area-L-11 1
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.newsletter-l-11 {
   .news-l-11-main-bg {
      background: #000;
      border-radius: 5px;
      z-index: 1;
      padding: 60px 0px 50px 0px;

      @include tablet {
         padding: 80px 0px 70px 0px;
      }

      @include desktops {
         padding: 115px 0px 112px 0px;
      }

   }

   .news-l-11-second-bg {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #258aff !important;
      transform: rotate(-4deg) !important;
      border-radius: 5px;
      z-index: -1;
   }

   .content {
      h5 {
         font-family: Karla;
         font-style: normal;
         font-weight: bold;
         font-size: 14px;
         line-height: 16px;
         text-align: center;
         letter-spacing: 2.625px;
         text-transform: uppercase;
         color: #ECF2FA;
         margin-bottom: 30px;
      }

      h2 {
         font-family: Gothic A1;
         font-style: normal;
         font-weight: 800;
         font-size: 42px;
         line-height: 52px;
         text-align: center;
         letter-spacing: -2.53px;
         color: #FFFFFF;
         margin-bottom: 20px;
   
         @include tablet {
            margin-bottom: 35px;
            font-size: 60px;
            line-height: 48px;
         }
       
      }

      p {
         font-family: Karla;
         font-style: normal;
         font-weight: normal;
         font-size: 18px;
         line-height: 32px;
         text-align: center;
         letter-spacing: -0.4px;
         color: #ECF2FA;

      }

      span {
         font-family: Karla;
         font-style: normal;
         font-weight: normal;
         font-size: 14px;
         line-height: 17px;
         text-align: center;
         color: #ECF2FA;
         display: inline-block;
         margin-top: 15px;
      }

      .btn {
         min-width: 226px;
         height: 60px;
         font-family: Karla;
         font-style: normal;
         font-weight: bold;
         font-size: 18px;
         line-height: 21px;
         letter-spacing: -1px;
         color: #FFFFFF;
         background: #258AFF;
         border: 2px solid #FFFFFF;
         box-sizing: border-box;
         border-radius: 3px;
         margin-top: 20px;
         @include tablet {
            margin-top: 30px;
         }
         i {
            margin-right: 20px;
         }
      }
   }

}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Footer-area-L-11 
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.footer-l-11 {
   padding-top: 70px;
   @include tablet {
      padding-top: 100px;
   }
 
}

.footer-widget-l-11 {
   margin: 30px 0px;
   @include tablet {
      margin: 0px 0px;
   }
   ul {
      margin: 0px -15px 0px -15px;

      li {
         margin: 0px 15px 0px 15px;

         a {
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.642857px;
            color: #333333;
         }
      }
   }
}

.social-icons-l-11 {
   ul {
      margin: 0px -15px 0px -15px;

      li {
         margin: 0px 15px 0px 15px;

         a {
            i {
               color: #000;
               font-size: 16px;
            }
         }
      }
   }
}

.copyright-area-l-11 {
   padding-top: 40px;
   padding-bottom: 30px;

   p {
      margin-bottom: 0;
      font-family: Karla;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.642857px;
      color: #666666;
   }
}