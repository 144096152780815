/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Hero Area-L2
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.hero-area-l2 {
    padding-top: 95px;
    padding-bottom: 50px;

    @include mobile {
        padding-top: 125px;
    }

    @include tablet {
        padding-top: 145px;
        padding-bottom: 66px;
    }

    @include desktops {
        padding-bottom: 0;
    }

    .content {
        padding-top: 45px;
        position: relative;
        z-index: 5;

        @include desktops {
            padding-top: 0;

        }

        h2 {
            color: $cloudburst;
            font-size: 34px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: -1px;
            line-height: 40px;
            margin-bottom: 35px;

            @include mobile-lg {
                font-size: 40px;
                line-height: 46px;
            }

            @include tablet {
                font-size: 48px;
                line-height: 54px;
            }

            @include desktops {
                font-size: 70px;
                line-height: 76px;
            }

            @include large-desktops {
                font-size: 80px;
                line-height: 86px;
            }
        }

        p {
            opacity: 0.7;
            color: $cloudburst;
            font-size: 21px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 32px;
            padding-right: 0px;

            @include large-desktops {
                padding-right: 110px;
            }
        }


    }
}

.header-btn-l2 {
    .sign-in-btn {
        font-weight: 600;
        font-size: 17px;
        color: $mirage;
        min-width: initial;
        padding: 0;
        margin-right: 40px;
    }

    .register-btn {
        font-weight: 600;
        border-radius: 10px;
        font-size: 17px;
        background: $cloudburst;
        color: $white;
        min-width: 130px;
        height: 60px;
    }
}

.hero-image-group-l2 {
    position: relative;

    @include desktops {
        margin-left: -80px;
    }

    .image-1 {
        & img {
            width: 100%;

            @include large-desktops {
                width: 110%;
            }

        }
    }

    .image-2 {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;

        & img {
            width: 300px;

            @include tablet {
                width: 586px;
            }
        }
    }
}

.popular-search-area {
    h6 {
        font-weight: 600;
        font-size: 17px;
        line-height: 30px;
        opacity: 1;
        color: $mirage;
        padding-top: 32px;

        span {
            opacity: 0.7;
            color: #213053;
        }
    }
}

.location-search-area {
    margin-top: 40px;

    @include tablet {
        margin-top: 55px;
    }

    label {
        color: $mirage;
        font-size: 17px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
    }

    &__search-form {
        width: 100%;
        box-shadow: 0px 44px 64px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        background-color: $white;
        padding: 20px 15px 20px 15px;
        margin-top: 20px;
        position: relative;
        z-index: 1;

        @include tablet {
            padding: 15px 15px 15px 20px;
            border-radius: 15px;
            width: 100%;
            height: 90px;

        }

        @include desktops {
            width: 746px;
        }

        .form-control {
            padding-left: 0;
            border: 0;
            font-size: 15px;
            line-height: 28px;
            color: $mirage;
            opacity: 0.5;
        }
    }



    &__dropdown {
        width: 100%;
        display: inline-block;

        @include mobile-lg {

            width: 235px;
        }

        .nice-select {
            padding-left: 0;
            height: 25px;


            .list {
                width: 200px;
            }

            &:after {
                border-bottom: 2px solid $secondary;
                border-right: 2px solid $secondary;
                height: 7px;
                margin-top: -4px;
                right: 12px;
                top: 70%;
                width: 7px;
            }
        }

        .current {
            font-size: 15px;
            line-height: 28px;
            color: $mirage;
            opacity: 0.5;
        }


        ul {
            li {
                color: $cloudburst;
                font-size: 15px;
                font-weight: 400;
                font-style: normal;
                line-height: normal;
            }
        }
    }

    &__search-btn {
        position: relative;
        margin-top: 10px;

        @include tablet {
            margin-top: -4px;
        }

        .btn {
            min-width: 100%;
            height: 50px;
            box-shadow: 21px 15px 23px rgba(65, 111, 244, 0.11);
            border-radius: 10px;
            background-color: $blackrock;
            color: $white;
            font-size: 17px;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;

            @include tablet {
                min-width: 160px;
                height: 60px;
            }

        }

    }

    .location-type {


        input {
            min-width: 100%;

            @include mobile-lg {
                margin-top: 0;
                min-width: 260px;
            }

            @include desktops {
                min-width: 315px;
            }
        }

        &__input {
            margin-top: 20px;

            @include mobile-lg {
                margin-top: 0;
            }
        }
    }


}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Brand Area-L2
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l1 {
    padding: 60px 0px;

    @include tablet {
        padding: 80px 0px;
    }

    @include desktops {
        padding: 90px 0px;
    }

    .brand-logos {
        margin: 0px -30px -30px -30px;

        .single-brand {
            margin: 0px 30px 30px 30px;
        }
    }
}

.brand-bottom-border {
    border-bottom: 1px solid $alto;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Features Area-L2
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-l2 {
    padding-top: 50px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 125px;
        padding-bottom: 130px;
    }
}

.feature-l2-items {
    padding-top: 25px;
    margin-bottom: -25px;

    @include tablet {
        padding-top: 35px;
    }

    @include desktops {
        padding-top: 45px;
    }

    .col-xl-3 {
        margin-bottom: 25px;
    }
}

.feature-box-l2 {
    border: 1px solid $alto;
    border-radius: 10px;
    padding: 30px 15px 7px 25px;
    transition: all 0.4s ease-in-out;

    &:hover {
        box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.1);
    }

    .color-box {
        height: 24px;
        width: 24px;
    }

    .content-box {
        margin-left: 25px;

        h4 {
            font-weight: 600;
            font-size: 21px;
            line-height: 30px;
            color: $cloudburst;
            margin-top: -6px;
        }

        p {
            font-size: 17px;
            line-height: 30px;
            color: $mirage;
            opacity: 0.7;
        }
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
 Featured Jobs Area-L2
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-job-area {
    padding-top: 50px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.feature-job-items {
    padding-top: 30px;
    margin-bottom: -25px;

    @include tablet {
        padding-top: 50px;
    }

    @include desktops {
        padding-top: 87px;
    }

    .col-xl-4 {
        margin-bottom: 25px;
    }
}

.feature-job-box {
    background: #FFFFFF;
    border: 1px solid rgba(223, 223, 223, 0.7);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 20px 30px 30px 30px;
    transition: all 0.4s ease-in-out;

    &:hover {
        box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.1);
    }


    .location {
        margin-bottom: 25px;

        i {
            color: $raven;
            font: 13px;
            margin-right: 12px;
        }

        span {
            font-size: 15px;
            line-height: 28px;
            color: $mirage;
            opacity: 0.7;
            display: inline-block;
        }
    }

    h4 {
        color: $mirage;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.3px;
    }

    p {
        font-size: 17px;
        line-height: 30px;
        color: $mirage;

        span {
            color: $secondary;
        }
    }

    .time-apply-area {
        padding-top: 12px;

        i {
            color: $raven;
            font: 13px;
            margin-right: 12px;
        }

        span {
            font-size: 15px;
            line-height: 28px;
            color: $mirage;
            display: inline-block;
            margin-top: 3px;
        }
    }
}

.view-all-job-btn-l1 {
    .btn {
        min-width: 151px;
        height: 60px;
        background: rgba(255, 135, 57, 0.2);
        border-radius: 10px;
        font-weight: 600;
        font-size: 15px;
        line-height: 26px;
        color: $secondary;
        margin-top: 30px;

        @include desktops {
            margin-top: 0;
        }
    }
}

.apply-now-btn-fj {
    .btn {
        border-radius: 10px;
        width: 100%;
        height: 50px;
        background: $java;
        font-weight: 600;
        font-size: 15px;
        line-height: 26px;
        color: $white;
        margin-top: 30px;

        @include mobile {
            min-width: 122px;
            margin-top: 0;
        }
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
 Content Area-L2-1
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-are-l2-1 {
    padding-top: 60px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 73px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .content {
        margin-bottom: 45px;

        @include large-desktops {
            margin-bottom: 0;
        }

        h2 {
            color: $blackrock;
            font-size: 34px;
            font-weight: 600;
            font-style: normal;
            letter-spacing: -1px;
            line-height: 44px;
            margin-bottom: 25px;

            @include mobile {
                font-size: 38px;
                line-height: 48px;
            }

            @include tablet {
                font-size: 48px;
                line-height: 58px;
            }

            @include desktops {
                font-size: 60px;
                line-height: 66px;
            }
        }

        p {
            opacity: 0.7;
            color: $mirage;
            font-size: 17px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: normal;
            line-height: 30px;
            margin-bottom: 0;
        }
    }

    & img {
        border-radius: 10px;
    }
}

.check-lis-btn-l2 {
    .btn {
        background: $secondary;
        color: $white;
        border-radius: 10px;
        font-weight: 600;
        font-size: 17px;
        line-height: 30px;
        min-width: 170px;
        height: 60px;
        margin-top: 45px;
    }
}

.content-l2-2-image-1 {
    position: relative;
    margin-bottom: 25px;

    @include tablet {
        margin-bottom: 0px;
    }

    .logo-1 {
        position: absolute;
        top: 10px;
        left: 10px;
    }
}

.content-l2-2-image-2 {
    position: relative;

    .logo-2 {
        position: absolute;
        bottom: 10px;
        right: 10px;

    }
}

.content-l2-2-image-3 {
    position: relative;
    margin-top: 25px;

    .logo-3 {
        position: absolute;
        bottom: 10px;
        left: 10px;

    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
 Testimonial Area-L2-1
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.testimonial-area-l2 {
    padding-top: 50px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 130px;
    }
}

.testimonial-slider-l2-1 {
    padding-top: 10px;

    @include tablet {
        padding-top: 20px;
    }

    @include desktops {
        padding-top: 48px;
    }
}

.testimonial-l2-single-card {
    background: $white;
    border: 1px solid rgba(223, 223, 223, 0.7);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 30px 25px 30px 25px;

    @include mobile {
        height: 100%;
    }

    .content {
        @include tablet {
            min-height: 250px;
        }

        @include desktops {
            min-height: 170px;
        }

        p {
            font-size: 21px;
            line-height: 32px;
            color: $mirage;
        }
    }


    .user-identity {
        background: rgba(214, 227, 235, 0.3);
        padding: 15px 20px;
        border-radius: 15px;
        margin-top: 40px;

        .user-image {
            img {
                margin: auto;
                margin-bottom: 15px;

                @include mobile {
                    margin-bottom: 0;
                    margin-right: 25px;
                }
            }
        }

        .user-details {
            h4 {
                font-weight: 600;
                font-size: 21px;
                line-height: 30px;
                color: $mirage;
            }

            p {
                font-size: 17px;
                line-height: 30px;
                color: $mirage;
                opacity: 0.7;
            }
        }
    }
}

.testimonial-slider-l2-1 {
    margin: 0px -15px;

    .single-item {
        margin: 0px 15px;
    }

    .slick-list {
        display: grid;
    }
}

.l2-slider-arrow-1 {
    margin: 0px -5px;
    margin-bottom: 40px;

    @include tablet {
        margin-bottom: 0px;
    }

    .fas {
        margin: 0px 5px;
        height: 60px;
        width: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 25px;
        color: $cloudburst;
        background: $white;
        border: 1px solid $alto;
        border-radius: 10px;

    }

    .slick-active {
        background: $secondary;
        color: $white;
        border: none;
    }
}




/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
 Footer Area-L2
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.footer-area-l2 {
    padding-top: 50px;

    @include tablet {
        padding-top: 70px;
    }

    @include desktops {
        padding-top: 120px;
    }
}

.footer-l2-area-items {
    padding-top: 30px;
    padding-bottom: 55px;

    @include tablet {
        padding-top: 50px;
        padding-bottom: 75px;
    }

    @include desktops {
        padding-top: 92px;
        padding-bottom: 105px;
    }
}



.subscribe-newsletter-l2 {
    position: relative;
    margin-top: 40px;
    width: 100%;

    @include mobile-lg {
        background-color: $white;
        border-radius: 10px;
        height: 80px;
    }

    @include tablet {
        margin-top: 50px;
        width: 670px;

    }

    @include desktops {
        margin-top: 70px;
    }

    .form-control {
        font-size: 16px;
        font-weight: 400;
        color: $mirage;
        font-style: normal;
        letter-spacing: -0.53px;
        line-height: normal;
        border: 0;
        height: 70px;
        border-radius: 10px;
        background-color: $white;
        padding: 0px 30px 0px 30px;

        @include mobile-lg {
            height: 80px;
            padding: 0px 187px 0px 30px;
        }

        &::placeholder {
            color: $mirage;
            opacity: 0.3;
        }
    }

    .btn {
        color: $white;
        width: 100%;
        height: 60px;
        font-weight: 600;
        font-size: 17px;
        border-radius: 10px;
        background-color: $mirage;
        margin-top: 20px;

        @include mobile-lg {
            width: 160px;
            margin-top: 0;
            position: absolute;
            right: 10px;
        }



    }
}

.footer-menu-item-l2 {
    margin: 0px -20px;

    li {
        display: inline-flex;
        flex-wrap: wrap;

        a {
            text-decoration: none;
            font-weight: 600;
            font-size: 17px;
            line-height: 30px;
            color: $mirage;
            margin: 0px 20px;
        }
    }

}

.footer-social-icon-l2 {
    margin: 25px -20px 0px -20px;

    @include desktops {
        margin: 0px 20px 0px 20px;
    }

    li {
        display: inline-flex;
        flex-wrap: wrap;

        a {
            i {
                font-size: 20px;
                color: $mirage;
                margin: 0px 20px;
            }
        }
    }

}

.footer-logo-l2 {
    & img {
        margin-bottom: 30px;

        @include desktops {
            margin-bottom: 0;
        }
    }
}