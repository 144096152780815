/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Service 01
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.inner-service-banner {
        background: #F9FAFB;
        padding-top: 123px;
        padding-bottom: 60px;
        position: relative;

        @include mobile {
                padding-top: 130px;
        }

        @include tablet {
                padding-top: 152px;
                padding-bottom: 80px;
        }

        @include desktops {
                padding-top: 200px;
                padding-bottom: 80px;
        }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Service 02
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.inner-service-01 {
        padding-top: 28px;

        @include tablet {
                padding-top: 48px;
        }

        @include desktops {
                padding-top: 78px;
        }
}

.inner-service-newsletter-01 {
        background: #F9FAFB;

        .newsletter-form-l5 .btn {
                background: #1787FC;
        }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Service Details content Area 1
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.service-details-1-content-1 {
        background: #F9FAFB;
        padding-bottom: 60px;

        @include tablet {
                padding-bottom: 80px;
        }

        @include desktops {
                padding-bottom: 130px;
        }

        .content {
                padding-top: 45px;

                @include desktops {
                        padding-top: 0;
                }

                h2 {
                        font-weight: 600;
                        font-size: 32px;
                        line-height: 42px;
                        letter-spacing: -1px;
                        color: #1D263A;
                        margin-bottom: 20px;
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        opacity: 0.8;
                }
        }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Service Details Process Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.service-details-process-area {
        padding-top: 50px;
        padding-bottom: 60px;

        @include tablet {
                padding-top: 70px;
                padding-bottom: 80px;
        }

        @include desktops {
                padding-top: 120px;
                padding-bottom: 130px;
        }

        .heading {
                h2 {
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 44px;
                        letter-spacing: -1px;
                        color: #1D263A;

                        @include tablet {
                                font-size: 42px;
                                line-height: 50px;
                        }
                }
        }
}

.service-details-process-items {
        position: relative;
        margin-top: 40px;
        margin-bottom: -30px;

        @include tablet {
                margin-top: 60px;
        }

        @include desktops {
                margin-top: 90px;

                &:after {
                        position: absolute;
                        content: "";
                        border-top: 3px dashed rgba(23, 98, 252, 0.2);
                        width: 75%;
                        top: 25px;
                        left: 40px;
                        z-index: -1;
                }
        }

        .col-lg-3 {
                margin-bottom: 30px;
        }

        .number-box {
                span {
                        filter: drop-shadow(0px 4px 25px rgba(23, 135, 252, 0.5));
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                        border-radius: 50%;
                        background: #1787FC;
                        font-weight: 600;
                        font-size: 28px;
                        letter-spacing: -0.5px;
                        color: #FFFFFF;
                        margin-bottom: 45px;
                }
        }

        .content-box {
                h6 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 21px;
                        line-height: 30px;
                        color: #1D263A;
                        margin-bottom: 20px;
                }

                p {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 28px;
                        color: rgba(2, 11, 18, 0.7);
                        margin-bottom: 0;
                }
        }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Service Details content Area 2
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.service-details-1-content-2 {
        background: #F9FAFB;
        padding-top: 60px;
        padding-bottom: 60px;

        @include tablet {
                padding-top: 80px;
                padding-bottom: 80px;
        }

        @include desktops {
                padding-top: 130px;
                padding-bottom: 130px;
        }

        .content-area-2 {
                padding-top: 60px;

                @include tablet {
                        padding-top: 80px;
                }

                @include desktops {
                        padding-top: 115px;
                }
        }

        .content {
                margin-bottom: -55px;
                padding-top: 45px;

                @include desktops {
                        padding-top: 0;
                }

                .card-item {
                        margin-bottom: 55px;

                        .icon-box {
                                margin-right: 30px;
                                margin-bottom: 30px;
                                border: 1.5px solid #1787FC;
                                width: 36px;
                                height: 36px;
                                line-height: 28px;
                                text-align: center;
                                border-radius: 50%;
                                display: inline-table;

                                @include mobile {
                                        margin-bottom: 0;
                                }
                        }

                        .content-box {
                                h6 {
                                        font-weight: 600;
                                        font-size: 21px;
                                        line-height: 30px;
                                        color: #1D263A;
                                        margin-bottom: 20px;
                                        margin-top: -5px;
                                }

                                p {
                                        font-size: 17px;
                                        line-height: 30px;
                                        color: #262729;
                                        opacity: 0.7;
                                        margin-bottom: 0;

                                }
                        }
                }

        }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Service Details Cta Area
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.service-details-1-cta-area {
        padding-top: 50px;
        @include tablet {
                padding-top: 70px;
        }
      
        @include desktops {
                padding-top: 90px;
        }
        .content {
                h2 {
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 44px;
                        letter-spacing: -1px;
                        color: #1D263A;
                        margin-bottom: 25px;

                        @include tablet {
                                font-size: 42px;
                                line-height: 50px;
                        }
                }

                p {
                        font-size: 15px;
                        line-height: 28px;
                        color: #1D263A;
                        opacity: 0.7;
                        margin-bottom: 0;
                }

        }

        .cta-req-btn {
                margin-top: 30px;
                @include desktops {
                        margin-top: 0;
                }
                .btn {
                        min-width: 263px;
                        height: 55px;
                        background: #20BFA9;
                        border-radius: 3px;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 30px;
                        color: #FFFFFF;
                }
        }

        .cta-border {
                border-top: 1px solid rgba(29,36,58,0.13);
                margin: 70px 0px 0px 0px;
                @include tablet {
                        margin: 90px 0px 0px 0px;
                }
              
               
        }

}