/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Hero Area-L-17

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-17-menu {
  .site-menu-main .nav-link-item {
    font-family: Karla;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;

    i {
      color: #fff;
    
    }
  }
}

.landing-17-menu {
  .site-menu-main .sub-menu--item {
    font-family: Karla;
    color: #1f1f1f;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: -0.4px;
  }
}
.landing-17-menu{
  .menu-block.active{
      .nav-link-item i{
          color: #000;
      }
  }
}

.landing-17-menu {
  &.reveal-header .sign-in-btn {
    color: #fff !important;
  }
}
.landing-17-menu.reveal-header .nav-link-item {
  color: #fff !important;
}
.landing-17-menu.reveal-header {
  background: #1D263A !important;
}
.hero-area-l-17 {
  background: url(../image/l8/hero-bg.png);
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  padding-top: 107px;
  padding-bottom: 60px;
  z-index: 5;

  &:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, #152649 0%, #4f525c 100%);
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .8;
  }

  @include mobile {
    padding-top: 130px;
  }

  @include tablet {
    padding-top: 150px;
    padding-bottom: 75px;
  }

  @include desktops {
    padding-top: 155px;
    padding-bottom: 0;
  }

  .content {
    padding-top: 45px;

    @include desktops {
      padding-top: 0;
      padding-bottom: 90px;
    }

    h1 {
      font-family: Inter;
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 46px;
      letter-spacing: -0.3px;
      color: #fff;

      @include mobile-lg {
        font-size: 40px;
        line-height: 52px;
      }

      @include tablet {
        font-size: 46px;
        line-height: 60px;
      }


    }

    p {

      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 34px;
      color: #fff;
      margin: 5px 0px 30px 0px;

    }

    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      color: #DBE4F1;
      display: block;
      margin-top: 10px;
    }

    .btn {
      background: #50E3C2;
      border-radius: 3px;
      min-width: 227px;
      height: 50px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #0F3D33;

      &:hover {
        color: #0F3D33 !important;
      }
    }



  }

  .hero-area-image {
    & img {
      box-shadow: 0px 22px 74px rgba(20, 21, 24, 0.1845);
      border-radius: 5px;
    }

    @include desktops {
      margin-bottom: -180px;
    }

  }

}


.header-btn-l-17 {
  .sign-in-btn {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #DBE4F1;
    min-width: initial;
    padding: 0;
    margin-right: 20px;
  }

  .start-trail-btn {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -1px;
    color: #0F3D33;
    min-width: 163px;
    height: 50px;
    background: #50E3C2;
    box-sizing: border-box;
    border-radius: 3px;
    margin-left: 15px;
  }

}




/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
             Feature Area-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.feature-area-l-17 {

  padding-top: 60px;
  padding-bottom: 50px;

  @include tablet {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  @include desktops {
    padding-top: 110px;
    padding-bottom: 105px;
    margin-top: 180px;
  }

  .feature-area-l-17-items {
    margin-bottom: -30px;

    .col-lg-4 {
      margin-bottom: 30px;
    }

    .single-features {
      padding: 0px 30px;

      @include desktops {
        padding: 0px 20px;
      }

      @include large-desktops {
        padding: 0px 35px;
      }

      .circle-dot-1 {
        i {
          font-size: 10px;
          color: #FC5C65;
          height: 50px;
          width: 50px;
          line-height: 50px;
          text-align: center;
          border-radius: 50%;
          background: rgba(252, 92, 101, 0.06);
          margin-bottom: 35px;
        }
      }

      .circle-dot-2 {
        i {
          font-size: 10px;
          color: #50E3C2;
          height: 50px;
          width: 50px;
          line-height: 50px;
          text-align: center;
          border-radius: 50%;
          background: rgba(80, 163, 227, 0.06);
          margin-bottom: 35px;
        }
      }

      .circle-dot-3 {
        i {
          font-size: 10px;
          color: #A665FB;
          height: 50px;
          width: 50px;
          line-height: 50px;
          text-align: center;
          border-radius: 50%;
          background: rgba(166, 101, 251, 0.06);
          margin-bottom: 35px;
        }
      }

      h4 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 34px;
        color: #000000;
        margin-bottom: 25px;
      }

      p {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 34px;
        color: #323232;
        margin-bottom: 0;
      }

      @include tablet {
        &.single-border:after {
          position: absolute;
          content: "";
          right: -15px;
          top: 50%;
          height: 111px;
          transform: translateY(-50%);
          width: 1px;
          background: #E8E8EA;
        }
      }
    }
  }
}





/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Content Area 1-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-17-1 {
  background: #F8FBFE;
  padding-top: 60px;
  padding-bottom: 60px;

  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include desktops {
    padding-top: 130px;
    padding-bottom: 240px;
  }

  .content-img {
    .image-2 {
      position: absolute;
      top: 30%;
      right: 8%;
      display: none;

      @include mobile {
        display: block;
      }
    }

    & img {
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.103346);
      border-radius: 5px;
      width: 100%;

      @include mobile {
        width: initial;
      }
    }
  }

  .content {
    padding-top: 45px;

    @include mobile {
      margin-top: 105px;
    }

    @include desktops {
      margin-top: 0;
      padding-top: 0;
    }

    .btn {
      min-width: 227px;
      height: 50px;
      background: rgba(80, 227, 194, 0.14);
      mix-blend-mode: normal;
      border-radius: 3px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #0F3D33;
      margin-top: 40px;

      &:hover {
        color: #0F3D33 !important;
      }
    }
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Content Area 2-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-17-2 {
  padding-top: 60px;
  padding-bottom: 60px;

  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include desktops {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .content-img {
    .image-2 {
      position: absolute;
      top: 17%;
      right: -3%;
      z-index: -1;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) -23.88%, #FFFFFF 100%);
      display: none;

      @include mobile {
        display: block;
      }
    }

    & img {
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.103346);
      border-radius: 5px;
      width: 100%;

      @include mobile {
        width: initial;
      }
    }
  }

  .content {
    padding-top: 45px;

    @include desktops {
      padding-top: 0;
    }

    .btn {
      min-width: 227px;
      height: 50px;
      background: rgba(80, 227, 194, 0.14);
      mix-blend-mode: normal;
      border-radius: 3px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #0F3D33;
      margin-top: 40px;

      &:hover {
        color: #0F3D33 !important;
      }
    }
  }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Video Area-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.video-area-l-17 {
  position: relative;
  background: url(../image/l8/video-bg.png);
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 60px;
  padding-bottom: 40px;
  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(-45deg, #152649 0%, #4f525c 100%);
    top: 0;
    left: 0;
    z-index: -1;
    opacity: .6;
  }

  @include tablet {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  @include desktops {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .video-content {
    a {
      i {
        background: #fff;
        width: 96px;
        height: 96px;
        line-height: 96px;
        border-radius: 50%;
        text-align: center;
        color: #50E3C2;
        margin-bottom: 40px;

        @include tablet {
          margin-bottom: 55px;
        }
      }
    }

    h2 {
      font-family: Inter;
      font-style: normal;
      font-weight: bold;
      font-size: 38px;
      line-height: 50px;
      letter-spacing: -0.3px;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

    p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      color: #FFFFFF;
    }

  }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Pricing Area-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.pricing-area-l-17 {
  padding-top: 50px;
  padding-bottom: 60px;

  @include tablet {
    padding-top: 70px;
    padding-bottom: 80px;
  }

  @include desktops {
    padding-top: 120px;
    padding-bottom: 130px;
  }

  .content {
    margin-bottom: 40px;

    @include tablet {
      margin-bottom: 50px;
    }

    @include desktops {
      margin-bottom: 60px;
    }
  }
}


.pricing-area-l-17 {
  .single-price {
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 42px 54px rgba(0, 0, 0, 0.0939481);
    border-radius: 6px;
  }

  .toggle-btn {
    margin-bottom: 40px;
  }

  .single-price {
    padding: 30px 45px 15px 45px;

    .price-top {
      span {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 32px;
        letter-spacing: 0.433333px;
        text-transform: uppercase;
        color: #878B90;
        display: inline-block;
        margin-bottom: 30px;

      }
    }

    .main-price {
      span {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -1.2px;
        color: #000000;
        display: inline-block;
        margin-right: 8px;
      }

      h2 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 60px;
        letter-spacing: -0.3px;
        color: #000000;
        margin-top: -8px;

        span {
          font-family: Inter;
          font-style: normal;
          font-weight: bold;
          font-size: 17px;
          line-height: 21px;
          letter-spacing: -0.3px;
          color: #000000;
          display: inline-block;
          margin-left: 5px;

        }
      }

      p {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 23px;
        letter-spacing: -0.4px;
        color: #323232;
      }

    }

    .price-body {
      margin-top: 25px;

      ul {
        li {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 32px;
          letter-spacing: -0.45px;
          color: #000000;
          margin-bottom: 8px;

          i {
            margin-right: 10px;
          }
        }
      }

      .del {
        color: #8E8E8E;
      }

      del {
        color: #8E8E8E;
      }
    }

    .price-btn {
      .btn {
        min-width: 227px;
        height: 50px;
        background: #50E3C2;
        border-radius: 3px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #0F3D33;
        margin-top: 22px;
      }

      p {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.4px;
        color: #666666;
        margin-top: 10px;
      }


    }
  }

  [data-pricing-dynamic][data-value-active="yearly"] {
    .dynamic-value {
      &:before {
        display: inline-block;
        content: attr(data-yearly);
      }
    }

    [data-pricing-trigger] {
      background: $primary;

      span {
        left: calc(100% - 24px);
      }
    }

    .btn-toggle-2[data-pricing-trigger] {
      background: $primary;

      span {
        left: calc(100% - 52%);
      }

      &.active {
        &:before {
          color: #0F3D33 !important;
        }

        &:after {
          color: #0F3D33 !important;
        }
      }
    }

  }

  [data-pricing-dynamic][data-value-active="monthly"] {
    .dynamic-value {
      &:before {
        display: inline-block;
        content: attr(data-monthly);
      }
    }

  }

  .btn-toggle-2[data-pricing-trigger] {
    &.active {
      &:before {
        color: #0F3D33 !important;
      }

      &:after {
        color: #0F3D33 !important;
      }
    }
  }

  .dynamic-value {
    &:before {
      display: inline-block;
      content: attr(data-active);
    }
  }

  .static-value {
    &:before {
      display: inline-block;
      content: attr(data-active);
    }
  }

  .btn-toggle {
    width: 48px;
    height: 24px;
    border-radius: 17px;
    background-color: #f8f8f8 !important;
    position: relative;
    margin: 0 15px;

    span {
      width: 16px;
      height: 16px;
      background-color: #0F3D33;
      position: absolute;
      left: 5px;
      top: 4px;
      transition: 0.4s;
      border-radius: 500px;
      pointer-events: none;
    }

    &-2 {
      width: 220px;
      height: 42px;
      border-radius: 30px;
      background-color: #fff !important;
      border: 1px solid #e5e5e5 !important;
      position: relative;
      margin: 0 15px;
      z-index: 1;

      &:before {
        font-family: Inter;
        position: absolute;
        content: "Monthly";
        width: 46%;
        font-size: 12px;
        letter-spacing: -0.3px;
        font-weight: 600;
        text-transform: uppercase;
        color: #0F3D33;
        height: 34px;
        background: transparent;
        left: 9px;
        top: 4px;
        line-height: 34px;
      }

      &:after {
        font-family: Inter;
        position: absolute;
        content: "Yearly";
        width: 46%;
        font-size: 12px;
        letter-spacing: -0.3px;
        font-weight: 600;
        text-transform: uppercase;
        color: #0F3D33;
        height: 34px;
        background: transparent;
        right: 9px;
        top: 4px;
        line-height: 34px;
      }

      .round {
        width: 50%;
        /* left: 0 !important; */
        height: 34px;
        top: 3px;
        left: 4px;
        background: #50E3C2;
        display: block;
        z-index: -1;
      }

      span {
        display: none;
      }

    }

    &[data-value-active="yearly"] {
      background-color: #0F3D33 !important;

      span {
        left: calc(100% - 25px) !important;
      }
    }

    &-square {
      height: 65px;
      display: inline-flex;
      position: relative;
      z-index: 1;
      padding: 5px;
      align-items: center;

      &.bg-whisper-3 {

        &:hover,
        &:focus {
          background: #0F3D33 !important;
        }
      }

      span {
        min-width: 160px;
        min-height: 50px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0F3D33;

        &:first-child {
          border-radius: 10px 0 0 10px;
        }

        &:nth-child(2) {
          border-radius: 0px 10px 10px 0;
        }

        &.active {
          color: #fff;
          background: #0F3D33;
        }
      }

    }
  }


  .popular-pricing {
    margin-top: 25px;

    @include tablet {
      margin-top: 0;
    }

    &:before {
      position: absolute;
      content: "";
      left: 0px;
      top: 0px;
      width: 100%;
      height: 4px;
      border-radius: 6px;
    }



    &.popular-pricing-3 {
      &:before {
        height: 3px;
        top: -3px;
        background: #50E3C2;
      }
    }
  }


}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Brand Area-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.brand-area-l-17 {
  padding-bottom: 60px;

  @include tablet {
    padding-bottom: 80px;
  }

  @include desktops {
    padding-bottom: 130px;
  }

  .content {
    p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      color: #878B90;
      margin-bottom: 45px;
    }
  }
}

.brand-area-l-17-items {
  margin: 0px -20px -45px -20px;

  .single-brand {
    margin: 0px 20px 45px 20px;
  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Testimonial Area-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.testimonial-area-l-17 {
  padding-bottom: 60px;

  @include tablet {
    padding-bottom: 80px;
  }


  .testimonial-card {
    padding: 20px 30px 37px 30px;

    @include tablet {
      padding: 20px 45px 37px 45px;
    }

    @include desktops {
      padding: 20px 30px 37px 30px;
    }

    @include large-desktops {
      padding: 20px 45px 37px 45px;
    }

    & img {
      margin-right: 20px;
    }

    p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: -0.1px;
      color: #000000;
      padding: 55px 30px 22px 0px;
      min-height: 240px;
    }

    .user-identity {
      h5 {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.3px;
        color: #323232;
        margin-bottom: 0;

      }

      span {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: -0.3px;
        color: #323232;
        display: inline-block;
      }
    }
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            CTA Area-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.cta-area-l-17 {
  padding-bottom: 60px;

  @include tablet {
    padding-bottom: 80px;
  }

  h2 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 50px;
    letter-spacing: -0.221053px;
    color: #000000;
    margin-bottom: 30px;

    @include mobile-lg {
      margin-bottom: 0;
    }

  }

  .btn {
    background: #50E3C2;
    border-radius: 3px;
    min-width: 227px;
    height: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #0F3D33;

  }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Footer Area-L-17
          <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.footer-area-l-17 {
  background: linear-gradient(-45deg, #152649 0%, #4f525c 100%);
  padding-top: 60px;
  padding-bottom: 35px;

  @include tablet {
    padding-top: 80px;
  }

  @include desktops {
    padding-top: 115px;
  }

  .footer-area-l-17-items {
    margin-bottom: -30px;

    .col {
      margin-bottom: 30px;
    }
  }

  .footer-shape {
    position: absolute;
    top: -12px;
    left: 0;
    width: 102%;

    @include mobile {
      top: -9px;
    }

    @include desktops {
      top: -17px;
    }

    @include large-desktops {
      top: -30px;
    }

    & img {
      width: 100%;
    }
  }

  .footer-widget {
    p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
      color: #DBE4F1;
      mix-blend-mode: normal;
      opacity: 0.7;
      margin-bottom: 30px;
    }

    ul {
      margin-bottom: -12px;

      li {
        margin-bottom: 12px;

        a {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 34px;
          color: #F8FBFE;
        }
      }
    }
  }
}

.copyright-area-l-17 {
  margin-top: 50px;

  @include tablet {
    margin-top: 70px;
  }

  @include desktops {
    margin-top: 100px;
  }

  .copyright {
    p {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #DBE4F1;
      margin-bottom: 0;

    }
  }

  .footer-menu {
    margin: 20px 0px 30px 0px;

    @include tablet {
      margin: 0;
    }

    ul {
      margin: 0px -10px -15px -10px;

      li {
        margin: 0px 10px 15px 10px;

        a {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: #DBE4F1;
        }
      }
    }
  }

  .social-icons {
    ul {
      margin: 0px -8px 0px -8px;

      li {
        margin: 0px 8px 0px 8px;

        i {
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }
}