/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Contact-01
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.inner-pages-banner {
        background: #F9FAFB;
        padding-top: 123px;
        padding-bottom: 60px;
        position: relative;

        @include mobile {
                padding-top: 130px;
        }

        @include tablet {
                padding-top: 152px;
                padding-bottom: 80px;
        }

        @include desktops {
                padding-top: 200px;
                padding-bottom: 80px;
        }
}


.inner-contact-form-area-1 {
        background: url(../image/contact/contact-bg-1.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 60px 0px 60px 0px;
        @include tablet {
                padding: 80px 0px 80px 0px;
        }
      
        @include desktops {
                padding: 100px 0px 180px 0px;
        }
}


.inner-contact-form-1 {
       
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.15);
        border-radius: 15px;
        padding: 30px;

        @include mobile-lg {
                padding: 30px 45px 40px 45px;
        }
        @include desktops {
                margin-top: -340px;
        }
        @include extra-large-desktops {
                margin-top: -320px;
        }
        
      
        .form-group {
                margin-bottom: 30px;
        }

        label {
                font-weight: 600;
                font-size: 17px;
                line-height: 30px;
                color: $mirage;
                margin-bottom: 5px;
        }

        .form-control {
                background: #E1ECF0;
                border-radius: 10px;
                height: 50px;
                font-size: 15px;
                line-height: 28px;
                color: #717986;
                border: 0;
                padding-left: 22px;
                padding-right: 22px;

                &::placeholder {
                        color: #717986;
                }
        }

        textarea {
                background: #E1ECF0;
                border-radius: 10px;
                height: 100px !important;

                @include tablet {
                        height: 180px !important;
                }
        }

        .btn {
                background: #20BFA9;
                border-radius: 50px;
                width: 100%;
                height: 56px;
                font-weight: 600;
                font-size: 17px;
                line-height: 30px;
                color: $white;

        }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Contact-02
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

    .inner-contact-area-2{
            padding-top: 0;
            background: #F9FAFB;
            .cta-form-area-l9 {
                border-radius: 0px 0px 10px 10px;
                
            }
            .cta-form-area-l9 .form-control {
                border-radius: 0;
            }
    }

    .inner-contact-2-items-area{
        padding-bottom: 10px;
        background: #F5F5F5;
         padding-top: 6 0px;
         @include tablet {
                padding-top: 80px;
                padding-bottom: 30px;
        }
      
        @include desktops {
                padding-top: 100px;
                padding-bottom: 80px;
        }
            .inner-contact-2-items-area-items{
                   
                    .col-lg-4{
                          margin-bottom: 40px;
                    }
            }
            .card-area{
                .icon-box{
                        margin-bottom: 30px;
                        margin-right: 30px;
                        height: 60px;
                        width: 60px;
                        line-height: 60px;
                        border-radius: 50%;
                        text-align: center;
                        background: rgba(214, 227, 235, 0.4);
                        display: inline-table;
                        & img{
                                width: 24px;
                                height: 24px;
                        }
                        @include mobile {
                                margin-bottom: 0;
                        }
                }
                .content-box{
                        margin-top: -5px;
                        h4{
                                font-style: normal;
                                font-weight: 600;
                                font-size: 24px;
                                line-height: 32px;
                                letter-spacing: -0.3px;
                                color: #1D263A;
                                margin-bottom: 20px;
                        }
                        p{
                                font-size: 21px;
                                line-height: 32px;
                                color: #1D263A;
                                opacity: 0.8;
                                margin-bottom: 0;
                        }
                }
            }
    }