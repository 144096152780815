/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Hero Area-L-18

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-18-menu {
    .site-menu-main .nav-link-item {
        font-family: Karla;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.4px;

        i {
            color: #fff;
        }
    }
}

.landing-18-menu {
    .site-menu-main .sub-menu--item {
        font-family: Karla;
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.4px;
    }
}

.landing-18-menu {
    &.reveal-header .sign-in-btn {
        color: #fff !important;
    }
}

.landing-18-menu{
    .menu-block.active{
        .nav-link-item i{
            color: #000;
        }
    }
}


.landing-18-menu.reveal-header .nav-link-item {
    color: #fff !important;
}

.landing-18-menu.reveal-header {
    background: #1D263A !important;
}

.hero-area-l-18 {
    background: linear-gradient(180deg, #0a74ff 0%, #6451ec 100%);
    padding-top: 93px;
    padding-bottom: 0px;

    @include mobile {
        padding-top: 115px;
    }

    @include tablet {
        padding-top: 135px;
    }

    @include desktops {
        padding-top: 205px;
        padding-bottom: 0;
    }

    .content {
        @include desktops {
            margin-top: -11px;
            padding-top: 0;
        }

        @include large-desktops {
            padding-right: 30px;
        }

        h1 {
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            font-size: 34px;
            line-height: 46px;
            letter-spacing: -0.3px;
            color: #fff;

            @include mobile-lg {
                font-size: 40px;
                line-height: 52px;
            }

            @include tablet {
                font-size: 46px;
                line-height: 58px;
            }

            @include desktops {
                font-size: 56px;
                line-height: 68px;
            }

        }

        p {

            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 34px;
            color: #DBE4F1;
            margin: 25px 0px 0px 0px;

        }


    }

    .download-btns {
   padding-top: 45px;
   @include desktops {
    padding-top: 0;
}

        .dw-wi-btn {
            margin-bottom: 20px;
            width: 310px;
            background: #FFFFFF;
            border-radius: 5px;
            padding: 20px 20px 12px 20px;
        }


        .icon-box {
            & img {
                margin-right: 18px;
            }
        }

        .text {
            h6 {
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #222D3D;
                margin: -4px 0px 0px 0px;
            }

            span {
                display: inline-block;
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 28px;
                color: #878B90;
            }
        }

        .dw-mac-btn {
            width: 310px;
            background: #4482FF;
            border-radius: 5px;
            padding: 20px 20px 12px 20px;

            h6 {
                color: #fff;
            }

            span {
                color: #fff;
            }
        }

    }

    .hero-area-image {
        & img {
            margin-top: 60px;
            @include tablet {
                margin-top: 80px;
            }
            @include desktops {
                margin-top: 120px;
            }

        }

    }

}


.header-btn-l-18 {
    .trail-btn {
        background: #EFD358;
        border-radius: 3px;
        min-width: 145px;
        height: 45px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #222D3D;
        margin-left: 15px;
    }


}




/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Brand Area-L-18
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.brand-area-l-18 {
    padding-top: 35px;
    padding-bottom: 60px;

    @include tablet {
        padding-bottom: 70px;
    }


    .content {
        p {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #878B90;
            margin-bottom: 45px;
        }
    }
}

.brand-area-l-18-items {
    margin: 0px -20px -45px -20px;

    .single-brand {
        margin: 0px 20px 45px 20px;
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Feature Area-L-18
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.feature-area-l-18 {
    background: #F8FBFE;
    padding-top: 50px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 120px;
        padding-bottom: 130px;
    }

    .section-heading-10 {
        margin-bottom: 40px;

        @include tablet {
            margin-bottom: 60px;
        }

        @include desktops {
            margin-bottom: 80px;
        }
    }
}

.feature-card-l-18-items {
    padding-top: 45px;
    margin-bottom: -20px;
    @include desktops {
    padding-top: 0;
    }
}

.feature-card-l-18 {
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 30px 30px 30px 30px;

    .icon-box {
        margin-bottom: 30px;
        margin-right: 25px;
        height: 58px;
        width: 58px;
        line-height: 58px;
        text-align: center;
        border-radius: 50%;
        background: #EEF5FF;
        display: inline-table;
        @include mobile {
            margin-bottom: 0;
        }

    }

    .content-body {
        h5 {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #222D3D;
        }

        p {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #878B90;
            margin-bottom: 0;
        }
    }
}

.feature-l-18-main-image {

    @include desktops {
        position: absolute;
        top: 0;
        width: 99%;
    }
    @include large-desktops {
        width: 82%;
    }
    @include extra-large-desktops {
        width: 72%;
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Content Area-1-L-18
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-18-1 {
    padding-top: 50px;

    @include tablet {
        padding-top: 70px;
    }

    @include desktops {
        padding-top: 115px;

    }

    .content {
        padding-top: 45px;

        @include desktops {
            padding-top: 0px;

        }

        h6 {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.3px;
            color: #FA8231;
            margin-bottom: 22px;
        }

        .btn {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #2780FF;
            border-bottom: 2px solid #2780ff;
            padding: 0;
            min-width: initial;
            border-radius: 0;
            margin-top: 28px;

            &:hover {
                color: #2780FF !important;
            }
        }
    }
    
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Content Area-1-L-18
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-18-2 {
    padding-top: 45px;
    padding-bottom: 60px;

    @include tablet {
        padding-top: 65px;
        padding-bottom: 80px;
    }

    @include desktops {
        padding-top: 115px;
        padding-bottom: 130px;
    }

    .content {
        padding-top: 45px;

        @include desktops {
            padding-top: 0px;

        }

        h6 {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.3px;
            color: #878B90;
            margin-bottom: 22px;
        }

        .btn {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #2780FF;
            border-bottom: 2px solid #2780ff;
            padding: 0;
            min-width: initial;
            border-radius: 0;
            margin-top: 28px;

            &:hover {
                color: #2780FF !important;
            }
        }
    }
    .content-img{
        & img{
            width: 100%;
            @include mobile {
                width: initial;
            }
        }
    }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Testimonial Area-1-L-18
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.testimonial-area-l-18 {
    background: url(../image/l9/testimonial-bg.png);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    padding-top: 60px;
    padding-bottom: 45px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 65px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 113px;
    }

    .content {
        padding-top: 45px;
        @include desktops {
            padding-top: 0;
        }
        h2 {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;
            line-height: 46px;
            letter-spacing: -0.3px;
            color: #FFFFFF;
            @include mobile-lg {
                font-size: 38px;
                line-height: 50px;
            }
          
        }

        .btn {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #EFD358;
            border-bottom: 2px solid #EFD358;
            padding: 0;
            min-width: initial;
            border-radius: 0;
            margin-top: 28px;

            &:hover {
                color: #EFD358 !important;
            }
        }
    }
}

.testimonial-logo-l-18 {
    margin-top: 40px;
    margin-bottom: -35px;
    @include tablet {
        margin-top: 70px;
    }
  
    @include desktops {
        margin-top: 105px;
    }
    .col-sm-6 {
        margin-bottom: 35px;
    }
}

.testimonial-card-slider-l-18 {

    & img {
        border-radius: 10px 10px 0px 0px;
    }

    .single-item {
        background: #FFFFFF;
        border-radius: 10px;

        .video-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            i {
                color: #fff;
                width: 68px;
                height: 68px;
                line-height: 68px;
                text-align: center;
                border-radius: 50%;
                background: rgba(39, 102, 255, 0.9);

            }
        }

        .content-body {
            padding: 45px 30px 40px 30px;

            @include tablet {
                padding: 45px 50px 40px 50px;
            }

            p {
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 32px;
                letter-spacing: -0.1px;
                color: #000000;
            }

            .user-identity {
                margin-top: 15px;

                h5 {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: -0.3px;
                    color: #323232;
                }

                span {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 28px;
                    letter-spacing: -0.3px;
                    color: #323232;
                }
            }
            .customer-img{
                margin-top: 15px;
                @include mobile {
                    margin-top: 0;
                }
            }
        }
    }

    ul.slick-dots li button {
        display: none;
    }

    ul.slick-dots {
        list-style: none;
        padding-left: 0;
        margin-top: 70px;
    }


    ul.slick-dots {
        margin-top: 25px;
        text-align: center;
        display: flex;
        justify-content: center;

        li {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            opacity: .3;
            margin: 0 4px;
            background: #fff;
            cursor: pointer;
        }

        li.slick-active {
            width: 10px !important;
            height: 10px !important;
            background: #fff !important;
            opacity: 1;
        }
    }


}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
            Newsletter Area-L-18
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-18 {
    padding-top: 50px;
    padding-bottom: 60px;
    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }
  
    @include desktops {
        padding-top: 90px;
        padding-bottom: 100px;
    }
    .download-btns {
        margin-bottom: -20px;
        margin-top: 45px;
        .dw-wi-btn {
            margin-bottom: 20px;
            width: 310px;
            border: 1px solid #DBE4F1;
            border-radius: 5px;
            padding: 20px 20px 12px 20px;
        }


        .icon-box {
            & img {
                margin-right: 18px;
            }
        }

        .text {
            h6 {
                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #222D3D;
                margin: -4px 0px 0px 0px;
            }

            span {
                display: inline-block;
                font-family: Inter;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 28px;
                color: #878B90;
            }
        }

    }

}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
        Footer Area-L-18
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.footer-area-l-18 {
    padding-top: 60px;
    padding-bottom: 30px;
    @include tablet {
        padding-top: 80px;
        padding-bottom: 50px;
    }
  
    @include desktops {
        padding-top: 110px;
        padding-bottom: 90px;
    }
    background: #F8FBFE;
    .footer-logo{
        margin-bottom: 40px;
    }
    .content{
        p{
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #666666;
            
        }
    }
    .social-icons {
        margin-top: 40px;
        @include tablet {
            margin-top: 50px;
        }
      
        @include desktops {
            margin-top: 60px;
        }
        ul {
          margin: 0px -8px 0px -8px;
    
          li {
            margin: 0px 8px 0px 8px;
    
            i {
              font-size: 20px;
              color: #000;
            }
          }
        }
      }
}
.footer-l-18-items{
    padding-top: 45px;
    @include desktops {
        padding-top: 0;
    }
    .footer-widget{
        p{
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 28px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #666666;
            margin-bottom: 25px;
        }
        ul{
            li{
                a{
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 40px;
                    letter-spacing: -0.3px;
                    color: #000000;  
                }
            }
        }
        &.widget3{
            @include mobile-lg {
                margin-top: 52px;
            }
           
        }
    }
}