/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Hero Area-L-12
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.landing-12-menu {
   .site-menu-main .nav-link-item {
      font-family: Karla;
      color: #1f1f1f;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.4px;

      i {
         color: #1f1f1f;
      }
   }
}

.landing-12-menu {
   .site-menu-main .sub-menu--item {
      font-family: Karla;
      color: #1f1f1f;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: -0.4px;
   }
}

.landing-12-menu {
   &.reveal-header .sign-in-btn {
      color: #666666 !important;
   }
}


.hero-area-l-12 {
   padding-top: 105px;
   padding-bottom: 30px;

   @include mobile {
      padding-top: 110px;
   }

   @include tablet {
      padding-top: 130px;
      padding-bottom: 55px;
   }

   @include desktops {
      padding-top: 225px;
      padding-bottom: 150px;
   }

   .content {
      position: relative;
      z-index: 5;
      padding-top: 45px;

      @include tablet {
         padding-right: 25px;
      }

      @include desktops {
         padding-top: 0;
         padding-right: 103px;

      }

      @include large-desktops {
         padding-right: 0;

      }

      @include extra-large-desktops {
         padding-right: 80px;
      }

      h1 {
         font-family: 'Gothic A1', sans-serif;
         color: #1F1F1F;
         font-size: 34px;
         font-weight: 800;
         font-style: normal;
         letter-spacing: -3px;
         line-height: 40px;
         margin-bottom: 10px;

         @include mobile-lg {
            font-size: 40px;
            line-height: 46px;
         }

         @include tablet {
            font-size: 48px;
            line-height: 54px;
         }

         @include large-desktops {
            font-size: 70px;
            line-height: 86px;
         }
      }

      p {
         font-family: Karla;
         color: #222F3F;
         font-size: 21px;
         font-weight: 400;
         font-style: normal;
         letter-spacing: -0.4px;
         line-height: 36px;



      }

      .btn {
         min-width: 210px;
         height: 60px;
         background: #FC593B;
         border-radius: 5px;
         font-family: Karla;
         font-style: normal;
         font-weight: bold;
         font-size: 18px;
         line-height: 21px;
         text-align: center;
         letter-spacing: -0.9px;
         color: #FFFFFF;
         margin-top: 22px;
      }


   }

}

.star-ratings-l12 {
   margin-top: 17px;

   i {
      color: #4EBE86;
   }

   p {

      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: -0.32381px;
      color: #878B90;
      margin-top: 8px;

      @include mobile {
         margin-top: 0;
         margin-left: 15px;
      }
   }

   .star-dif-color {
      color: #E5E5E5;
   }
}

.hero-video-l12 {
   .video-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      i {
         width: 100px;
         height: 100px;
         line-height: 100px;
         text-align: center;
         border-radius: 50%;
         background: #4EBE86;
         color: #fff;
      }
   }
}

.header-btn-l-12 {
   .sign-in-btn {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -1px;
      color: #666666;
      min-width: 87px;
      height: 40px;
      border-radius: 3px;
      margin-right: 15px;
   }

}




.hero-shape-l12-1 {
   position: absolute;
   left: 0;
   top: -41%;
   z-index: -1;

   @include tablet {
      left: -10%;
   }

   @include desktops {
      top: 30%;
      left: -5%;
      top: 0%;
   }
}

.hero-shape-l12-2 {
   position: absolute;
   right: -40%;
   top: 0%;

   @include desktops {
      right: 0;
   }
}

.video-area-shape-l-12 {
   position: absolute;
   right: -15%;
   bottom: -11%;
   z-index: -1;

}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Counter Area-L-12
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.counter-area-l12 {
   z-index: 5;

   h2 {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -1px;
      color: #333333;
      padding-bottom: 40px;
   }
}

.counter-area-l12-items {
   margin-bottom: -25px;

   .col-xxl-3 {
      margin-bottom: 25px;
   }
}

.counter-single-wrapper-l-12 {
   background: #FFFFFF;
   border: 1px solid #E5E5E5;
   box-sizing: border-box;
   box-shadow: 0px 10px 60px rgba(35, 44, 39, 0.0513757);
   border-radius: 10px;
   padding: 60px 30px 53px 30px;

   h5 {
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 60px;
      line-height: 65px;
      text-align: center;
      letter-spacing: -2px;
      color: #4EBE86;
   }

   p {

      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 25px;
      text-align: center;
      letter-spacing: -0.4px;
      color: #333333;
   }

   .counter_bg-img {
      position: absolute;
      left: 0;
      bottom: 0;
   }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Topics Area-L-12
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.topics-area-l-12 {
   background: #F7FBFC;
   padding-top: 55px;
   padding-bottom: 45px;
   z-index: 1;

   @include tablet {
      padding-top: 75px;
      padding-bottom: 65px;
   }

   @include desktops {
      padding-top: 125px;
      padding-bottom: 115px;
   }

   .section-heading-6 {
      padding-bottom: 40px;

      @include tablet {
         padding-bottom: 60px;
      }
   }

   .topics-area-l12-shape-1 {
      position: absolute;
      left: -4%;
      top: -10%;
      z-index: -1;
   }

   .topics-area-l12-shape-2 {
      position: absolute;
      right: -16%;
      top: 40%;
      transform: rotate(19deg);

      @include extra-large-desktops {
         right: -8%;
      }
   }
}

.faq-content-l-12 {
   .topics-accordion-wrapper {
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(37, 41, 39, 0.0322124);
      border-radius: 10px;
      padding: 30px 30px 25px 30px;
      margin-bottom: 15px;

      .topics-main-icon {
         margin-right: 25px;
         margin-bottom: 30px;

         @include mobile {
            margin-bottom: 0;
         }

         & img {
            padding: 22px;
            background: #EEF4F5;
            border-radius: 10px;
         }
      }

      .topics-collapse-wrapper {
         margin: 40px -22px 0px -22px;

         li {
            margin: 0px 22px;

            a {
               font-family: Karla;
               font-style: normal;
               font-weight: bold;
               font-size: 18px;
               line-height: 32px;
               letter-spacing: -0.857143px;
               color: #1F1F1F;
               display: flex;
               align-items: baseline;
            }
         }

         .fas {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            line-height: 22px;
            text-align: center;
            background: #DDF7EA;
            color: #4EBE86;
            font-size: 12px;
            margin-right: 13px;
         }
      }
   }

   .content {
      h3 {
         font-family: Work Sans;
         font-style: normal;
         font-weight: 600;
         font-size: 22px;
         line-height: 30px;
         letter-spacing: -1.26316px;
         color: #000000;
         margin-bottom: 10px;
         margin-top: -5px;


         @include mobile {
            font-size: 24px;
         }
      }

      span {
         font-family: Karla;
         font-style: normal;
         font-weight: normal;
         font-size: 17px;
         line-height: 28px;
         letter-spacing: -0.4px;
         color: #666666;
      }
   }

   .video-hour-area {
      background: #4EBE86;
      border-radius: 5px;
      padding: 5px 15px 5px 15px;

      margin-top: 20px;

      @include mobile-lg {
         margin-right: 20px;
      }

      @include desktops {
         margin-top: 0px;
      }

      li {
         font-family: Karla;
         font-style: normal;
         font-weight: bold;
         font-size: 15px;
         letter-spacing: -0.352941px;
         color: #FFFFFF;

         span {
            display: inline-block;
            height: 25px;
            width: 1px;
            background: #79D8A8;
            margin: 5px 20px 0px 20px;
         }
      }


   }

   .accordion-trigger {
      &.arrow-icon {
         position: relative;
         display: flex;
         align-items: center;
         justify-content: space-between;

         &:after {
            content: "\ea05";
            font-family: "Grayic";
            font-size: 30px;
            display: block;
            line-height: 1;
            transform: rotate(0deg);
            transition: .4s;
            color: #878b90;
            top: 7px;
            position: absolute;
            right: -10px;

         }

         &[aria-expanded="true"] {
            &:after {
               transform: rotate(-180deg);
            }
         }
      }

      &-2 {
         &.arrow-icon {
            &:after {
               top: 27px;
               right: 23px;
            }
         }
      }
   }

}




/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Content Area-L-12-1
        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-12-1 {
   padding-top: 60px;

   @include tablet {
      padding-top: 80px;
   }

   @include desktops {
      padding-top: 130px;
   }

   .main-img {

      @include desktops {
         padding-right: 100px;
      }

   }

   .content-area-l-12-shape {
      position: absolute;
      top: 15%;
      left: -7%;
      z-index: -1;
   }

   .content {
      padding-top: 45px;

      @include desktops {
         padding-top: 0;
      }

      a {
         font-family: Karla;
         font-style: normal;
         font-weight: bold;
         font-size: 21px;
         line-height: 36px;
         letter-spacing: -0.4px;
         color: #4EBE86;
         display: inline-block;
         margin-bottom: 40px;
      }
   }
}





/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Testimonial Area L-12
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.testimonial-area-l-12 {
   padding-bottom: 60px;

   @include tablet {
      padding-bottom: 80px;
   }

   @include desktops {
      padding-bottom: 130px;
   }

   .testimonial-border-area-l-12 {
      border-top: 1px solid #E5E5E5;
      margin: 50px 0px 50px 0px;

      @include tablet {
         margin: 70px 0px 70px 0px;
      }

      @include desktops {
         margin: 130px 0px 120px 0px;
      }
   }

   .testimonial-area-l12-shape {
      position: absolute;
      right: 5%;
      bottom: -33%;
   }



   position: relative;


   .section-heading-6 {
      @include desktops {
         padding-right: 35px;
      }

      @include large-desktops {
         padding-right: 85px;
      }


      h2 {
         margin-bottom: 30px;

         @include desktops {
            margin-bottom: 60px;
         }
      }
   }

}


.card--testimonial-l-12 {
   background: #FFFFFF;
   border: 1px solid #E5E5E5;
   box-sizing: border-box;
   border-radius: 10px;
   transition: all 0.4s ease-in-out;
   height: 100%;

}

.testimonial-l-12-content {

   padding: 25px 30px 27px 30px;

   span {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: -0.642857px;
      color: #666666;
   }

   h6 {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 17px;
      line-height: 28px;
      letter-spacing: -0.425px;
      color: #000000;
      margin-bottom: 0;

   }

   .video-area {
      i {
         width: 54px;
         height: 54px;
         line-height: 54px;
         border-radius: 50%;
         font-size: 12px;
         text-align: center;
         background: #4EBE86;
         color: #fff;
      }
   }
}

.l-12-slider-arrow-1 {
   margin-bottom: 30px;

   @include desktops {
      margin-bottom: 0px;
   }

   & i {
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: #4EBE86;
      background: #DDF7EA;
      border-radius: 50%;
      margin: 0px 6px;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      
   }
.slick-active{
   color: #fff;
   background: #4EBE86;
}

}

.testimonial-slider-l-12 {
   margin: 0px -15px;

   .single-item {
      margin: 0px 15px;
   }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     FAQ Area L-12
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/



.faq-area-l-12 {
   background: #283730;
   padding-top: 50px;
   padding-bottom: 60px;

   @include tablet {
      padding-top: 70px;
      padding-bottom: 55px;
   }

   @include desktops {
      padding-top: 120px;
      padding-bottom: 130px;
   }

   .section-heading-6 {
      padding-bottom: 40px;

      @include desktops {
         padding-bottom: 60px;
      }

      h2 {
         color: #fff;
      }
   }

   .faq-l-12-shape {
      position: absolute;
      left: 0;
      bottom: 0;
   }
}




.faq-area-l-12 {

   .accordion li {
      position: relative;
      list-style: none;
      background: #384E44;
      border: 1px solid #20342B;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(37, 41, 39, 0.0322124);
      border-radius: 10px;
      padding: 27px 30px 29px 30px;
      margin-bottom: 15px;

      &.active {
         background: #FFFFFF;
         border: 1px solid #E5E5E5;
         box-sizing: border-box;
         box-shadow: 0px 2px 4px rgba(37, 41, 39, 0.0322124);
         border-radius: 10px;

         a {
            color: #000;
         }
      }
   }

   .accordion li p {
      display: none;
      padding: 20px 15px 0px 0px;
      margin-bottom: 6px;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 28px;
      letter-spacing: -0.4px;
      color: #666666;

   }

   .accordion a {

      width: 100%;
      display: block;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      font-family: Work Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 25px;
      letter-spacing: -1px;
      position: relative;
      padding-right: 20px;

      &:hover {
         color: #fff;
      }

   }

   .accordion li .active {
      color: #000 !important;
   }

   .accordion .active:after {
      border-right: 2px solid #94AEA2 !important;
      border-bottom: 2px solid #94AEA2 !important;

   }

   .accordion a:after {
      width: 8px;
      height: 8px;
      border-right: 2px solid#94AEA2;
      border-bottom: 2px solid #94AEA2;
      position: absolute;
      right: 0px;
      content: " ";
      top: 8px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
   }


   .accordion a.active:after {
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      -moz-transform: rotate(225deg);
      -ms-transform: rotate(225deg);
      -o-transform: rotate(225deg);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
   }

}

.money-back-content-l12 {
   margin-top: 32px;

   @include tablet {
      margin-top: 52px;
   }

   @include desktops {
      margin-top: 80px;
   }

   h2 {
      font-family: Work Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 42px;
      line-height: 52px;
      letter-spacing: -2.33px;
      color: #FFFFFF;
      @include mobile-lg {
         font-size: 70px;
         line-height: 70px;
      }
    
      span {
         color: #4EBE86;
      }
   }

   p {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 36px;
      letter-spacing: -0.4px;
      color: #E5E5E5;
      margin-top: 20px;
   }

   .content-img {
      margin-top: 30px;

      @include tablet {
         margin-top: 0;
      }
   }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Newsletter Area L-12
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.newsletter-area-l-12 {
   padding-top: 60px;
   padding-bottom: 60px;

   @include tablet {
      padding-top: 80px;
      padding-bottom: 80px;
   }

   @include desktops {
      padding-top: 140px;
      padding-bottom: 140px;
   }

   .news-letter-l-12-shape {
      position: absolute;
      right: -15%;
      top: -47%;
   }

   span {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 2.8125px;
      text-transform: uppercase;
      color: #666666;

   }

   h2 {
      font-family: Work Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 34px;
      line-height: 45px;
      text-align: center;
      letter-spacing: -2.53px;
      color: #000000;
      margin: 25px 0px 40px 0px;

      @include mobile-lg {
         font-size: 42px;
         line-height: 53px;
      }

      @include tablet {
         font-size: 48px;
         line-height: 57px;
      }
   }

   .btn {
      min-width: 210px;
      height: 60px;
      background: #FC593B;
      border-radius: 5px;
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.9px;
      color: #FFFFFF;
   }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
     Footer Area L-12
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.footer-area-l-12 {
   padding-bottom: 60px;

   @include tablet {
      padding-bottom: 60px;
   }

   @include desktops {
      padding-bottom: 50px;
   }

   .footer-l-12-top-border {
      border-top: 1px solid #E5E5E5;
      margin: 0px 0px 45px 0px;

      @include tablet {
         margin: 0px 0px 65px 0px;
      }

      @include desktops {
         margin: 0px 0px 70px 0px;
      }
   }

   .footer-area-l-12-shape {
      position: absolute;
      left: -30%;
      bottom: 0%;

      @include desktops {
         left: -8%;
         bottom: -85%;
      }
   }

   .footer-widget {
      p {
         font-family: Karla;
         font-style: normal;
         font-weight: normal;
         font-size: 15px;
         line-height: 45px;
         letter-spacing: -0.321429px;
         color: #666666;

      }

      ul {
         margin-top: 25px;

         li {
            a {
               font-family: Karla;
               font-style: normal;
               font-weight: normal;
               font-size: 15px;
               line-height: 40px;
               letter-spacing: -0.4px;
               color: #000000;
            }
         }
      }

      &.widget3 {
         @include mobile-lg {
            margin-top: 70px;
         }

      }
   }
}

.footer-subs-form-l-12 {
   background: #DDF7EA;
   border-radius: 5px;
   padding: 20px 30px 30px 30px;
   margin-top: 20px;

   @include desktops {
      margin-top: 0;
   }

   p {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 45px;
      letter-spacing: -0.321429px;
      color: #666666;
      margin-bottom: 5px;
   }

   h6 {
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 28px;
      letter-spacing: -0.4px;
      color: #000000;
      margin-bottom: 20px;

   }

   .input-group {


      .form-control {
         background: #FFFFFF;
         border-radius: 5px;
         font-family: Karla;
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 28px;
         color: #666666;
         width: 177px;
         height: 56px;
         border: 0;
         padding-left: 20px;
         @include mobile {
            font-size: 15px;
            width: 320px;
         }
         @include desktops {
            width: 250px;

         }

         @include large-desktops {
            width: 320px;
         }

         &::placeholder {
            color: #666666;
         }
      }


      .btn {
         min-width: 60px;
         height: 56px;
         background: #4EBE86;
         border-radius: 0px 5px 5px 0px;

         i {
            color: #fff;
         }
      }
   }

}