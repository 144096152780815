/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Portfolio-01
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/




.portfolio-card-masonry {
        position: relative;
}

.portfolio-navbar {
        padding: 30px 0px;

        @include tablet {
                padding: 50px 0px;
        }

        ul {
                li {
                        a {
                                color: #6f727b;
                                font-size: 13px;
                                font-weight: 700;
                                font-style: normal;
                                letter-spacing: 1.63px;
                                line-height: normal;
                                text-align: center;
                                text-transform: uppercase;
                        }
                }
        }
}

.link-hover-light:hover {
        color: #495fef;
}

.isotope-nav li a.active {
        color: #495fef;
}

.portfolio-card-masonry:hover .text-block {
        bottom: 20px;
        opacity: 1;
}

.portfolio-card-masonry .text-block {
        position: absolute;
        bottom: 0px;
        left: 20px;
        right: 20px;
        opacity: 0;
        transition: .4s;
        padding: 30px 15px 15px 30px;
}

.nogap-btn-margin {
        margin-top: 45px;
}

.portfolio-card-hover .card-image {
        transition: .4s;
}

.portfolio-card-hover .card-image img {
        transition: .4s;
}

.portfolio-card-hover:hover .card-image {
        transform: scale(0.8) rotate(-16deg);
}

.portfolio-card-hover:hover img {
        border-radius: 20px;
        box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
}



.link-hover-reset:hover {
        color: var(--color-text);
}

.link-hover-rotate {
        transition: .4s;
        display: inline-flex;
        will-change: transform;
        transform: rotate(0deg);
}

.link-hover-rotate:hover {
        transform: rotate(-10deg);
}


.isotope-item {
        transition: .4s;
}

.gr-bg-opacity {
        z-index: 1;
}

.gr-bg-opacity:before {
        position: absolute;
        content: "";
        background: #fff;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        opacity: .9;
        border-radius: 8px;
}

.gr-pb-7,
.gr-py-7 {
        padding-bottom: 25px !important;
}


.portfolio-card-masonry {

        span {
                color: #161c2d;
                font-size: 13px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 1.63px;
                line-height: normal;
                opacity: 0.5;
                text-transform: uppercase;
                margin-bottom: 8px;
        }

        h3 {
                color: #161c2d;
                font-size: 24px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: -1.2px;
                line-height: 44px;

                @include tablet {
                        font-size: 26px;
                }
        }
}






.grid--no-gap {
        .col-md-6 {
                padding: 0;
        }

        .col-sm-6 {
                padding: 0;
        }

        .col-lg-4 {
                padding: 0;
        }

        .col-lg-3 {
                padding: 0;
        }

        .col-12 {
                padding: 0;
        }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Portfolio-03
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.portfolio-grid-area-3 {
        background: #F9FAFB;
        padding-bottom: 30px;

        @include tablet {
                padding-bottom: 50px;
        }

        @include desktops {
                padding-bottom: 100px;
        }

        .portfolio-grid-area-3-items {
                .portfolio-grid-3-card {
                        .content {
                                span {
                                        display: inline-block;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 22px;
                                        color: #FF794D;
                                        margin-bottom: 17px;
                                        margin-top: 20px;
                                }

                                h4 {
                                        font-weight: 600;
                                        font-size: 24px;
                                        line-height: 32px;
                                        letter-spacing: -0.3px;
                                        color: #1D263A;
                                        margin-bottom: 30px;
                                }
                        }

                        @include desktops {
                                &.port-margin-gap-1 {
                                        margin-top: 225px;
                                }

                                &.port-margin-gap-2 {
                                        margin-top: 155px;
                                }
                        }

                }
        }

}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Portfolio-Details-1
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.portfolio-details-area-1 {
        position: relative;
        background: #F9FAFB;
        padding-top: 128px;
        padding-bottom: 60px;
        position: relative;
        z-index: 1;

        @include mobile {
                padding-top: 140px;
        }

        @include tablet {
                padding-top: 160px;
                padding-bottom: 80px;
        }

        @include desktops {
                padding-top: 195px;
                padding-bottom: 100px;
        }

        @include desktops {
                &:after {
                        position: absolute;
                        content: '';
                        background: #000;
                        height: 100%;
                        width: 62%;
                        top: 0;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        left: 0;
                        opacity: 1;
                        z-index: -1;
                }
        }


        .portfolio-details-1-image {
                & img {
                        border-radius: 30px;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
        }

        .port-details-1-content {
                padding-top: 45px;

                @include desktops {
                        padding-top: 0px;
                }

                @include extra-large-desktops {
                        padding-left: 80px;
                }

                h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 44px;
                        ;
                        letter-spacing: -1px;
                        color: #262729;
                        margin-bottom: 30px;

                        @include mobile-lg {
                                font-size: 42px;
                                line-height: 50px;
                        }
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #262729;
                        opacity: 0.7;
                }

                .project-details {
                        margin-top: 45px;

                        ul {
                                margin-bottom: -15px;

                                li {
                                        margin-bottom: 15px;

                                        strong {
                                                font-weight: 600;
                                                font-size: 17px;
                                                line-height: 30px;
                                                color: #1D263A;
                                        }

                                        span {
                                                font-size: 17px;
                                                line-height: 30px;
                                                color: #1D263A;
                                                opacity: 0.8;
                                                margin-left: 15px;
                                        }
                                }
                        }
                }
        }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Portfolio-Details-2
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.portfolio-details-area-2 {
        position: relative;
        padding-top: 180px;
        padding-bottom: 165px;
        position: relative;
        background: url(../image/portfolio/portfolio-details-2-bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 1;


        @include tablet {
                padding-top: 250px;
                padding-bottom: 165px;
        }

        @include desktops {
                padding-top: 370px;
                padding-bottom: 300px;
        }
}

.portfolio-details-content-area-2 {
        background: #F9FAFB;
        padding-top: 65px;

        .content {
                @include extra-large-desktops {
                        padding-right: 30px;
                }

                h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 42px;
                        line-height: 50px;
                        letter-spacing: -1px;
                        color: #1D263A;
                        margin-bottom: 25px;
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        opacity: 0.8;
                        margin-bottom: 20px;
                }
        }
}

.project-info-area-pr-de-2 {
        padding-top: 45px;

        z-index: 1;
        position: relative;

        @include desktops {
                margin-top: -250px;
                padding-top: 0;
        }

        .port-details-1-content {
                background: #FFFFFF;
                box-shadow: 0px 54px 100px rgba(10, 4, 60, 0.1);
                border-radius: 15px;
                padding: 38px 30px 45px 30px;

                @include mobile {
                        padding: 38px 45px 45px 45px;
                }

                h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 34px;
                        line-height: 42px;
                        letter-spacing: -1px;
                        color: #1D263A;

                        @include mobile {
                                font-size: 42px;
                                line-height: 50px;
                        }

                }

                .btn {
                        min-width: 150px;
                        height: 55px;
                        background: #FF794D;
                        border-radius: 3px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 30px;
                        color: #FFFFFF;
                        margin-top: 40px;
                }

                .project-details {

                        margin-top: 38px;

                        ul {
                                margin-bottom: -15px;

                                li {
                                        margin-bottom: 15px;

                                        strong {
                                                font-weight: 600;
                                                font-size: 17px;
                                                line-height: 30px;
                                                color: #1D263A;
                                        }

                                        span {
                                                font-size: 17px;
                                                line-height: 30px;
                                                color: #1D263A;
                                                opacity: 0.8;
                                                margin-left: 15px;
                                        }
                                }
                        }
                }


        }

        p {
                font-size: 17px;
                line-height: 30px;
                color: #1D263A;
                opacity: 0.8;
                margin-top: 40px;
                margin-bottom: 0;
        }
}

.process-area-port-de-2 {
        background: #F9FAFB;
        padding-top: 50px;
        padding-bottom: 60px;

        @include tablet {
                padding-top: 70px;
                padding-bottom: 80px;
        }

        @include desktops {
                padding-top: 95px;
                padding-bottom: 130px;
        }

        .service-details-process-items {
                margin-top: 40px;
                z-index: 1;
        }
}

.key-challenges-area {
        background: #F9FAFB;
        padding-bottom: 40px;

        @include tablet {
                padding-bottom: 60px;
        }

        @include desktops {
                padding-bottom: 110px;
        }

        .content {
                padding-top: 45px;

                @include desktops {
                        padding-top: 0;
                }

                @include extra-large-desktops {
                        padding-right: 30px;
                }

                h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 42px;
                        line-height: 50px;
                        letter-spacing: -1px;
                        color: #1D263A;
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        opacity: 0.8;
                        margin-top: 35px;
                }
        }

        .challenge-list {
                margin-top: 30px;

                ul {
                        li {
                                margin-bottom: 15px;
                                display: flex;
                                align-items: center;

                                & img {
                                        margin-right: 15px;
                                        margin-top: -5px;
                                }

                                font-size: 17px;
                                line-height: 30px;
                                color: #1D263A;
                        }
                }
        }
}


.prev-next-project-area {
        padding-top: 35px;
        border-top: 1px solid rgba(29, 36, 58, 0.1);
        ;
        background: #F9FAFB;

        .prev-next-project-items {
                .col-md-6 {
                        margin-bottom: 30px;
                }

                .project-link-area {
                        .icon-box {
                                i {
                                        background: #1787FC;
                                        transform: matrix(-1, 0, 0, 1, 0, 0);
                                        height: 65px;
                                        width: 65px;
                                        line-height: 65px;
                                        color: #fff;
                                        text-align: center;
                                        font-size: 20px;
                                        margin-right: 25px;
                                }
                        }

                        .content-box {
                                span {
                                        display: inline-block;
                                        font-size: 17px;
                                        line-height: 30px;
                                        color: #1D263A;
                                        opacity: 0.8;
                                        margin-bottom: 3px;
                                }

                                h6 {
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 21px;
                                        line-height: 30px;
                                        color: #1D263A;
                                        margin-bottom: 0;
                                }
                        }

                        &.project-link-area-next {
                                i {
                                        margin-left: 25px;
                                        margin-right: 0;
                                }
                        }
                }
        }
}



/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Portfolio-Details-3
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.portfolio-details-area-3 {
        background: #F9FAFB;
        position: relative;
        padding-top: 130px;
        padding-bottom: 50px;
        position: relative;

        @include mobile {
                padding-top: 140px;
        }

        @include tablet {
                padding-top: 160px;
        }

        @include desktops {
                padding-top: 200px;

        }

        .product-details-slider {
                margin-bottom: 25px;

                @include tablet {
                        margin-bottom: 0;
                }

                & img {
                        border-radius: 15px;
                        object-fit: fill;

                        @include tablet {
                                height: 295px;
                        }

                        @include desktops {
                                height: 390px;
                        }

                        @include large-desktops {
                                height: 468px;
                        }

                        @include extra-large-desktops {
                                height: 538px;
                        }
                }


        }

        .product-details-vertical-slider {

                margin: 0px -12px;

                .single-slide {
                        margin: 0px 12px;
                }

                @include tablet {
                        .single-slide {
                                margin-bottom: 25px;
                        }
                }

        }
}

.project-information-pd-3 {
        background: #F9FAFB;
        padding-bottom: 60px;

        @include tablet {
                padding-bottom: 80px;
        }

        @include desktops {
                padding-bottom: 100px;
        }

        .content {
                h2 {
                        font-weight: 600;
                        font-size: 34px;
                        line-height: 42px;
                        letter-spacing: -1px;
                        color: #1D263A;
                        margin-bottom: 20px;

                        @include mobile {
                                font-size: 42px;
                                line-height: 50px;
                        }
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        opacity: 0.8;
                        margin-bottom: 20px;
                }
        }

        .btn {
                min-width: 150px;
                height: 55px;
                background: #1787FC;
                border-radius: 3px;
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 30px;
                color: #FFFFFF;
                margin-top: 40px;
        }

        .project-details {

                margin-top: 38px;

                ul {
                        margin-bottom: -15px;

                        li {
                                margin-bottom: 15px;

                                strong {
                                        font-weight: 600;
                                        font-size: 17px;
                                        line-height: 30px;
                                        color: #1D263A;
                                }

                                span {
                                        font-size: 17px;
                                        line-height: 30px;
                                        color: #1D263A;
                                        opacity: 0.8;
                                        margin-left: 15px;
                                }
                        }
                }
        }
}

.project-brief-area {
        .product-image {
                & img {

                        object-fit: fill;

                        @include desktops {
                                height: 240px;
                        }

                        @include large-desktops {
                                height: 300px;
                        }

                        @include extra-large-desktops {
                                height: 380px;
                        }
                }
        }

        .col-md-8 {
                @include tablet {
                        margin-top: 25px;
                }

                @include desktops {
                        margin-top: 0;
                }
        }

        .col-md-6 {
                margin-bottom: 25px;

                @include tablet {
                        margin-top: 0;
                }
        }
}

.project-brief-area {
        padding-top: 50px;

        @include tablet {
                padding-top: 70px;
        }

        @include desktops {
                padding-top: 120px;
        }

        .content {
                h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 46px;
                        letter-spacing: -1px;
                        color: #1D263A;
                        margin-bottom: 22px;
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        opacity: 0.8;
                }
        }

        .project-brief-area-items {
                padding-bottom: 45px;
        }
}

.client-feed-back-port-3 {


        .heading {
                padding: 40px 0px;

                @include tablet {
                        padding: 60px 0px;
                }

                h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 34px;
                        line-height: 42px;
                        letter-spacing: -1px;
                        color: #1D263A;

                        @include mobile {
                                font-size: 42px;
                                line-height: 50px;
                        }
                }
        }

        .content-l5-3-content {
                background: linear-gradient(270.32deg, #047AF5 0.34%, rgba(23, 135, 252, 0.6) 99.79%);
        }
}

.similar-project-area {
        background: #F9FAFB;
        padding-top: 50px;
        padding-bottom: 35px;

        .section-heading-14 {
                margin-bottom: 40px;

                @include tablet {
                        margin-bottom: 60px;
                }

                @include desktops {
                        margin-bottom: 80px;
                }
        }

        .col-lg-4 {
                margin-bottom: 25px;
        }

        @include tablet {
                padding-top: 70px;
                padding-bottom: 55px;
        }

        @include desktops {
                padding-top: 120px;
                padding-bottom: 105px;
        }
}




/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
      Portfolio-Details-4
    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.portfolio-details-area-4 {
        background: #F9FAFB;
        position: relative;
        padding-top: 130px;
        padding-bottom: 50px;
        position: relative;

        @include mobile {
                padding-top: 140px;
        }

        @include tablet {
                padding-top: 160px;
                padding-bottom: 70px;
        }

        @include desktops {
                padding-top: 210px;
                padding-bottom: 120px;

        }
}

.portfolio-details-area-4-bg {
        background: #FFFFFF;
        border-radius: 15px;
        padding-top: 50px;
        padding-bottom: 60px;

        @include tablet {
                padding-top: 70px;
                padding-bottom: 80px;
        }

        @include desktops {
                padding-top: 100px;
                padding-bottom: 90px;
        }

        .heading {
                margin-bottom: 40px;

                @include tablet {
                        margin-bottom: 50px;
                }

                @include desktops {
                        margin-bottom: 60px;
                }

                h2 {
                        font-weight: 600;
                        font-size: 34px;
                        line-height: 42px;
                        letter-spacing: -1px;
                        color: #1D263A;
                        margin-bottom: 20px;

                        @include mobile {
                                font-size: 42px;
                                line-height: 50px;
                        }
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        opacity: 0.8;
                }
        }
}

.portfolio-det-area-4-bg-image {
        position: relative;
        padding-top: 115px;
        padding-bottom: 115px;
        background: url(../image/portfolio/portfolio-bg-4.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 1;

        @include tablet {
                padding-top: 170px;
                padding-bottom: 170px;
        }

        @include desktops {
                padding-top: 250px;
                padding-bottom: 250px;
        }
}

.project-details-item-4-items {
        margin-top: 70px;

        .col-lg-3 {
                margin-bottom: 30px;
        }

        .content {
                h6 {
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        margin-bottom: 13px;
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        opacity: 0.8;
                }
        }

        .btn {
                min-width: 150px;
                height: 55px;
                background: #1787FC;
                border-radius: 3px;
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 30px;
                color: #FFFFFF;
                margin-top: 25px;
                @include tablet {
                        margin-top: 30px;
                }
              
                @include desktops {
                        margin-top: 40px;
                }
        }
}

.process-area-pro-det-4 {
        background: #F9FAFB;
        position: relative;
        z-index: 0;
        padding-bottom: 55px;

        @include tablet {
                padding-bottom: 75px;
        }

        @include desktops {
                padding-bottom: 125px;
        }

        .content {
                h2 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 36px;
                        line-height: 46px;
                        letter-spacing: -1px;
                        color: #1D263A;
                        margin-bottom: 22px;
                }

                p {
                        font-size: 17px;
                        line-height: 30px;
                        color: #1D263A;
                        opacity: 0.8;
                }
        }
}