/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Hero Area-L-14

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.landing-14-menu {
    .site-menu-main .nav-link-item {
        font-family: Karla;
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.4px;

        i {
            color: #1f1f1f;
        }
    }
}

.landing-14-menu {
    .site-menu-main .sub-menu--item {
        font-family: Karla;
        color: #1f1f1f;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: -0.4px;
    }
}

.landing-14-menu {
    &.reveal-header .sign-in-btn {
        color: #666666 !important;
    }
}


.hero-area-l-14 {
    padding-top: 105px;
    padding-bottom: 0px;

    @include mobile {
        padding-top: 110px;
    }

    @include tablet {
        padding-top: 110px;
        padding-bottom: 0px;
    }

    @include desktops {
        padding-top: 225px;
        padding-bottom: 25px;
    }

    .content {
        position: relative;
        z-index: 5;
        padding-top: 25px;

        @include desktops {
            padding-top: 0;
        }

        @include large-desktops {
            padding-right: 0;

        }

        @include extra-large-desktops {
            padding-right: 82px;
        }

        h1 {
            font-family: Rubik;
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 40px;
            letter-spacing: -3.11px;
            color: #1F1F1F;
            margin-bottom: 10px;

            @include mobile-lg {
                font-size: 40px;
                line-height: 46px;
            }

            @include tablet {
                font-size: 60px;
                line-height: 66px;
            }

            @include large-desktops {
                font-size: 70px;
                line-height: 76px;
            }
        }

        p {
            font-family: Karla;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 36px;
            letter-spacing: -0.3px;
            color: #222F3F;
            margin: 18px 0px 30px 0px;



        }

        .btn {
            min-width: 205px;
            height: 60px;
            background: #333333;
            border-radius: 3px;
            font-family: Karla;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            letter-spacing: -0.4px;
            color: #FFFFFF;
            margin-top: 10px;
        }


    }

    .bg-shape-14 {
        background: url(../image/l4/hero-bg.svg);
        background-repeat: no-repeat;
        position: absolute;
        top: 0px;
        height: 100%;
        width: 100%;
        left: 0;
        transform: rotate(0deg);
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;

        @include desktops {
            top: -25px;
        }

        @include large-desktops {
            top: -15px;
        }
    }


}


.header-btn-l-14 {
    .trail-btn {
        font-family: Karla;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.4px;
        color: #FFFFFF;
        min-width: 152px;
        height: 40px;
        background: #333333;
        border-radius: 3px;
        margin-left: 15px;
    }

}

.banner-image-l-14 {
    padding-top: 45px;

    @include desktops {
        padding-top: 0;
        margin-top: 100px;
    }

    @include large-desktops {
        margin-top: -100px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
  Brand Area-L-14

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/


.brand-area-l-14 {
    padding-top: 60px;
    padding-bottom: 50px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 70px;
    }

    @include desktops {
        padding-top: 100px;
        padding-bottom: 130px;
    }

    h4 {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 28px;
        text-align: center;

        color: #333333;
        padding-bottom: 35px;
    }


}

.brand-area-l-14-items {
    margin: 0px -20px -45px -20px;

    .single-brand {
        margin: 0px 20px 45px 20px;
    }
}

.brand-aarea-border-l14 {
    border-top: 1px solid #E4E4E4;
    margin: 60px 0px 0px 0px;

    @include tablet {
        margin: 80px 0px 0px 0px;
    }

    @include desktops {
        margin: 100px 0px 0px 0px;
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Content-area-1-L-14

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-14-1 {

    .content {
        padding-top: 45px;
        @include desktops {
            padding-top: 0;
        }
        .btn {
            border: 0;
            padding: 0;
            min-width: initial;
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 28px;
            letter-spacing: -0.4px;
            color: #0C39F8;
            margin-top: 30px;
            @include tablet {
                margin-top: 50px;
            }
            i {
                margin-left: 22px;
            }
            &:hover{
                color: #0C39F8 !important;
            }
        }
    }
}

/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Content-area-2-L-14

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.content-area-l-14-2 {
    padding-top: 80px;
    padding-bottom: 30px;

    @include tablet {
        padding-top: 80px;
        padding-bottom: 30px;
    }

    @include desktops {
        padding-top: 130px;
        padding-bottom: 30px;
    }

    .content {
        padding-top: 45px;
        @include desktops {
            padding-top: 0;
        }
        .btn {
            border: 0;
            padding: 0;
            min-width: initial;
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 28px;
            letter-spacing: -0.4px;
            color: #0C39F8;
            margin-top: 30px;
            @include tablet {
                margin-top: 50px;
            }
            i {
                margin-left: 22px;
            }
            &:hover{
                color: #0C39F8 !important;
            }
        }
    }
}




/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Feature Areea L-14

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/

.features-area-l-14 {
    .features-area-l-14 {
        margin-bottom: -45px;

        .col-lg-6 {
            margin-bottom: 45px;
        }
    }

    .bg-shape-img-2 {
        background: #0C39F8;
        padding: 80px 0px;

        @include desktops {
            background: url(../image/l4/features-bg.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0%;
            padding: 175px 30px 112px 30px;
        }

        @include large-desktops {
            padding: 175px 87px 112px 87px;
        }

        @include extra-large-desktops {
            padding: 190px 175px 190px 175px;
        }


    }

    .content-area {
        img {
            margin-right: 25px;
            @include mobile-lg {
                margin-right: 60px;
            }
        }

        h5 {
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: 32px;
            letter-spacing: -1.33333px;
            color: #FFFFFF;
            margin-bottom: 20px;
        }

        p {
            font-family: Karla;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 28px;
            /* or 165% */

            letter-spacing: -0.4px;

            color: #E4E4E4;
        }
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Pricing Area-L-14

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.pricing-area-l14 {
    padding-top: 50px;
    padding-bottom: 60px;
    @include tablet {
        padding-top: 70px;
        padding-bottom: 80px;
    }
  
    @include desktops {
        padding-top: 120px;
        padding-bottom: 130px;
    }
    .section-heading-8 {
        margin-bottom: 70px;
    }

    .l-14-pricing-table {
        margin-top: 40px;



        .single-price {
            .btn {
                min-width: 200px;
                height: 50px;
                background: #333333;
                border-radius: 3px;
                font-family: Karla;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */

                text-align: center;
                letter-spacing: -0.4px;

                color: #FFFFFF;
                margin-bottom: 40px;

            }

            .price-btn-bro {
                .btn {
                    background: #14EE80;
                    color: #333333;
                }
            }

            span {
                font-family: Karla;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 32px;
                /* identical to box height, or 267% */

                text-align: center;
                text-transform: uppercase;

                color: #666666;

            }

            .main-price {
                .price {
                    span {
                        font-family: Rubik;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 58px;
                        line-height: 50px;
                        /* identical to box height, or 86% */

                        text-align: center;

                        color: #000000;
                    }

                    h2 {
                        font-family: Rubik;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 58px;
                        line-height: 50px;
                        /* identical to box height, or 86% */

                        text-align: center;

                        color: #000000;
                    }
                }

                span {
                    font-family: Karla;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 15px;
                    line-height: 28px;
                    /* identical to box height, or 187% */

                    text-align: center;
                    letter-spacing: -0.428571px;

                    color: #666666;
                    display: inline-block;
                    margin-bottom: 25px;
                }


            }

            .price-body {
                ul {
                    padding: 15px 0px 15px 0px;

                    li {
                        font-family: Rubik;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 53px;
                        /* or 331% */

                        text-align: center;
                        letter-spacing: -0.761905px;

                        color: #1F1F1F;
                    }
                }

            }
        }
    }

    [data-pricing-dynamic][data-value-active="yearly"] .dynamic-value:before {
        display: inline-block;
        content: attr(data-yearly);
    }

    [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] {
        background: #1290a4;
    }

    [data-pricing-dynamic][data-value-active="yearly"] [data-pricing-trigger] span {
        left: calc(100% - 24px);
    }

    [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] {
        background: #1290a4;
    }

    [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger] span {
        left: calc(100% - 52%);
    }

    [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:before {
        color: #666666 !important;
    }

    [data-pricing-dynamic][data-value-active="yearly"] .btn-toggle-2[data-pricing-trigger].active:after {
        color: #fff !important;
    }

    [data-pricing-dynamic][data-value-active="monthly"] .dynamic-value:before {
        display: inline-block;
        content: attr(data-monthly);
    }

    .btn-toggle-2[data-pricing-trigger].active:before {
        color: #fff !important;
    }

    .btn-toggle-2[data-pricing-trigger].active:after {
        color: #666666 !important;
    }

    .dynamic-value:before {
        display: inline-block;
        content: attr(data-active);
    }

    .static-value:before {
        display: inline-block;
        content: attr(data-active);
    }

    .btn-toggle {
        width: 48px;
        height: 24px;
        border-radius: 17px;
        background-color: #f8f8f8 !important;
        position: relative;
        margin: 0 15px;
    }

    .btn-toggle span {
        width: 16px;
        height: 16px;
        background-color: #1290a4;
        position: absolute;
        left: 5px;
        top: 4px;
        transition: 0.4s;
        border-radius: 500px;
        pointer-events: none;
    }

    .btn-toggle-2 {
        width: 220px;
        height: 42px;
        border-radius: 30px;
        background-color: #fff !important;
        border: 1px solid #e5e5e5 !important;
        position: relative;
        margin: 0 15px;
        z-index: 1;
    }

    .btn-toggle-2:before {
        position: absolute;
        content: "Monthly";
        width: 46%;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        height: 34px;
        background: transparent;
        left: 9px;
        top: 4px;
        line-height: 34px;
    }

    .btn-toggle-2:after {
        position: absolute;
        content: "Yearly";
        width: 46%;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
        height: 34px;
        background: transparent;
        right: 9px;
        top: 4px;
        line-height: 34px;
    }

    .btn-toggle-2 .round {
        width: 50%;
        /* left: 0 !important; */
        height: 34px;
        top: 3px;
        left: 4px;
        background: blue;
        display: block;
        z-index: -1;
    }

    .btn-toggle-2 span {
        display: none;
    }

    .btn-toggle[data-value-active="yearly"] {
        background-color: #1290a4 !important;
    }

    .btn-toggle[data-value-active="yearly"] span {
        left: calc(100% - 25px) !important;
    }

    .btn-toggle-square {
        height: 65px;
        display: inline-flex;
        position: relative;
        z-index: 1;
        padding: 5px;
        align-items: center;
    }

    .btn-toggle-square.bg-whisper-3:hover,
    .btn-toggle-square.bg-whisper-3:focus {
        background: #8e8e8e !important;
    }

    .btn-toggle-square span {
        min-width: 160px;
        min-height: 50px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
    }

    .btn-toggle-square span:first-child {
        border-radius: 10px 0 0 10px;
    }

    .btn-toggle-square span:nth-child(2) {
        border-radius: 0px 10px 10px 0;
    }

    .btn-toggle-square span.active {
        color: #fff;
        background: #1290a4;
    }

    .popular-pricing:before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 100%;
        height: 4px;
        background: #f46f7c;
        border-radius: 6px;
    }

    .popular-pricing.popular-pricing-2:before {
        height: 3px;
        top: -3px;
        background: #14ee80;
    }

    .popular-pricing.popular-pricing-3:before {
        height: 3px;
        top: -3px;
        background: #50e3c2;
    }

    .active-border {
        position: relative;
    }

    .active-border:before {
        position: absolute;
        content: "";
        left: 0px;
        top: 0px;
        width: 100%;
        height: 5px;
        background: #5029de;
        border-radius: 5px;
    }

}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Cta Area-L-14

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/
.cta-area-l-14 {
    padding-bottom: 50px;
    @include tablet {
        padding-bottom: 70px;
    }
  
    @include desktops {
        padding-bottom: 120px;
    }
    .bg-shape-img-3 {
        height: 100%;
        background: #0C39F8;
       
       
        @include desktops {
            background: url(../image/l4/cta-bg.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: left bottom;
            width: 97%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            
            
        }
    }

    .cta-area-l-14-content {
        padding: 40px 0px 60px 0px;
        @include tablet {
            padding: 60px 0px 80px 0px;
        }
      
        @include desktops {
            padding: 100px 0px 72px 0px;
        }
        h2 {
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 40px;
            line-height: 50px;
            /* identical to box height, or 154% */

            letter-spacing: -2.52632px;

            color: #FFFFFF;
            @include tablet {
                font-size: 48px;
                line-height: 60px;
            }
        }

        .btn {
            background: #14EE80;
            border-radius: 3px;
            min-width: 195px;
            height: 60px;
            font-family: Karla;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            letter-spacing: -0.4px;

            color: #333333;
            margin-top: 20px;
        }
    }
}


/*>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>      
   Footer Area-L-14

        <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/